import React from "react";

import Calendar from "assets/images/calendar.svg";
import View from "assets/images/view.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CalendarInActive from "assets/images/calendarInActive.svg";
import ViewInActive from "assets/images/viewInActive.svg";

import ZoomOut from "assets/images/zoomout.svg";
import ZoomIn from "assets/images/zoomin.svg";
import VOD from "assets/images/vod.svg";
import LSPlay from "assets/images/lsplay.svg";
import Info from "assets/images/info.svg";
import Filter from "assets/images/filter.svg";
import Download from "assets/images/download.svg";
import Export from "assets/images/download-export.svg";
import FilterRollover from "assets/images/filter-rollover.svg";
import ExportRollover from "assets/images/download-export-rollover.svg";
import ExportDisable from "assets/images/download-export-disable.svg";
import HelpCenter from "assets/images/help-center.svg";
import VisitSite from "assets/images/visite-site.svg";
import HelpCenterRollOver from "assets/images/help-center-rollover.svg";
import VisitSiteRollOver from "assets/images/visite-site-rollover.svg";
import VisitSiteDisabled from "assets/images/visite-site-disabled.svg";
const Images = {
  FormControlLabel,
  CalendarInActive,
  ViewInActive,
  ZoomIn,
  ZoomOut,
  VOD,
  LSPlay,
  Info,
  Filter,
  Download,
  Export,
  FilterRollover,
  ExportRollover,
  ExportDisable,
  HelpCenter,
  VisitSite,
  HelpCenterRollOver,
  VisitSiteRollOver,
  VisitSiteDisabled,
};
export default Images;
