import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import * as ApiConstants from "constants/apiConstants";
import { ApiResponse } from "apisauce";
import {
  userApi,
  uploadApi,
  downloadreportApi,
  tenantHeaderApi,
  tenantHeaderTransform,
} from "api/api";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import {
  getChannelAvailabilityEnums,
  getCustomAPIErrorMessage,
  handleUnauthorized,
} from "utils/utilityFunctions";
import { apiResponseEnums, USER_MESSAGES } from "constants/enums";
import { setApiResponse } from "Layout/layoutSlice";
import { ErrorObjectType } from "models/common.model";
import { History } from "history";
import { editGeneralSettingsPath } from "constants/routeConstants";
import { TenantGeneralSettingsData, TenantGeneralSettingsDataAPI, TenantGeneralSettingsModel } from "models/Tenants/TenantsGeneralSettingsModel";

interface TenantState {
  tenantsData: any;
  error: string;
  isTenantCreated: boolean;
  createdTenant: any;
  tenantsTermsData: any;
  isLoading: boolean;
  isUploadTerms: boolean;
  isDownloadTerms: boolean;
  appKeyAndSecret: any;
  loadingAppKeyAndSecret: boolean;
  tenantGeneralSettingsData: any;
  tenantWebSettingsData: any;
  tenantAppSettingsData: any;
  tenantByIdData: any;
  isLoadingAPIData: boolean
}

const initialState: TenantState = {
  tenantsData: {},
  isTenantCreated: false,
  error: "",
  createdTenant: {},
  tenantsTermsData: {},
  isLoading: false,
  isUploadTerms: false,
  isDownloadTerms: false,
  appKeyAndSecret: {},
  loadingAppKeyAndSecret: false,
  tenantGeneralSettingsData: {},
  tenantWebSettingsData: {},
  tenantAppSettingsData: {},
  tenantByIdData: {},
  isLoadingAPIData: false,
};

export const tenantSlice = createSlice({
         name: "tenants",
         initialState,
         reducers: {
           getTenantList: (state, action: PayloadAction<any>) => {
             state.tenantsData = action.payload;
           },

           tenantCreated: (state, action: PayloadAction<any>) => {
             state.isTenantCreated = action.payload;
           },
           uploadTermsCreated: (state, action: PayloadAction<any>) => {
             state.isUploadTerms = action.payload;
           },
           downloadTermsCreated: (state, action: PayloadAction<any>) => {
             state.isDownloadTerms = action.payload;
           },
           errorInDownload: (state, action: PayloadAction<any>) => {
             state.error = action.payload;
           },
           errorInTenantCreation: (state, action: PayloadAction<any>) => {
             state.error = action.payload;
           },
           errorInTermsCreation: (state, action: PayloadAction<any>) => {
             state.error = action.payload;
           },
           setCreatedTenant: (state, action: PayloadAction<any>) => {
             state.createdTenant = action.payload;
           },
           getTenantTermsList: (state, action: PayloadAction<any>) => {
             state.tenantsTermsData = action.payload;
           },
           loadingTenantTermsList: (state, action: PayloadAction<any>) => {
             state.isLoading = action.payload;
           },
           setAppKeyAndSecret: (state, action: PayloadAction<any>) => {
             state.appKeyAndSecret = action.payload;
           },
           loadingAppKeyAndSecret: (state, action: PayloadAction<any>) => {
             state.loadingAppKeyAndSecret = action.payload;
           },
           setTenantGeneralSettings: (state, action: PayloadAction<any>) => {
             state.tenantGeneralSettingsData = action.payload;
           },
           setTenantWebSettings: (state, action: PayloadAction<any>) => {
             state.tenantWebSettingsData = action.payload;
           },
           setTenantAppSettings: (state, action: PayloadAction<any>) => {
             state.tenantAppSettingsData = action.payload;
           },
           setTenantById: (state, action: PayloadAction<any>) => {
             state.tenantByIdData = action.payload;
           },
           loadingApiData: (state, action: PayloadAction<any>) => {
             state.isLoadingAPIData = action.payload;
           },
         },
       });

export const {
  getTenantList,
  tenantCreated,
  errorInTenantCreation,
  setCreatedTenant,
  getTenantTermsList,
  uploadTermsCreated,
  errorInTermsCreation,
  errorInDownload,
  downloadTermsCreated,
  loadingTenantTermsList,
  setAppKeyAndSecret,
  loadingAppKeyAndSecret,
  setTenantGeneralSettings,
  setTenantWebSettings,
  setTenantAppSettings,
  setTenantById,
  loadingApiData
} = tenantSlice.actions;

// RR || The function below is called to check user auth status from firebase

export const publishChannelsOnTenant = (
  data: any,
  tenantId,
  tagsTobePublish,
  tagsToUnpublish
): AppThunk => (dispatch) => {
  let channels = [...data];
  tenantHeaderTransform();
  tenantHeaderApi
    .put(ApiConstants.updateChannelsInBulkAPI, channels)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        let payload = {
          ChannelIdsToPublish: tagsTobePublish,
          ChannelIdsToUnPublish: tagsToUnpublish,
        };
        tenantHeaderTransform(tenantId);
        tenantHeaderApi
          .put(ApiConstants.publishTenantsAPI, payload)
          .then((newRes) => {
            dispatch(getTenantsListfromApi());
            if (newRes.status === apiResponseEnums.DELETED) {
              dispatch(tenantCreated(true));
              setTimeout(function() {
                dispatch(tenantCreated(false));
              }, 2500);
              dispatch(
                setApiResponse({
                  status: res.status,
                  data: USER_MESSAGES.SAVED,
                })
              );
            } else {
              dispatch(errorInTenantCreation("Something went wrong"));
              setTimeout(function() {
                dispatch(errorInTenantCreation(""));
              }, 1500);
              dispatch(
                setApiResponse({
                  status: res.status,
                  data: getCustomAPIErrorMessage({
                    status: res.status as number,
                    data: res.data as ErrorObjectType,
                  }),
                })
              );
            }
          });
      } else {
        dispatch(errorInTenantCreation("Something went wrong"));
        setTimeout(function() {
          dispatch(errorInTenantCreation(""));
        }, 1500);
        const callback = () =>
          dispatch(
            regfeshTokenId(
              publishChannelsOnTenant(
                data,
                tenantId,
                tagsTobePublish,
                tagsToUnpublish
              )
            )
          );
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const getTenantWebSetApi = (tenantID): AppThunk => async (dispatch) => {
  try {
    dispatch(loadingApiData(true));
    dispatch(setTenantWebSettings({ tntWebSettingsData: [] }));
    const res = await userApi.post(ApiConstants.getTenantWebSet, {
      tenantID: tenantID,
    }).finally(() => {dispatch(loadingApiData(false))});
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setTenantWebSettings({ tntWebSettingsData: res.data as TenantGeneralSettingsData}));
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(getTenantsGeneralSetApi(tenantID)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setTenantGeneralSettings({ tntGeneralSettings: [] }));
    }
  } catch (error) {
    dispatch(
      setApiResponse({
        status: error as number,
        data: getCustomAPIErrorMessage({
          status: error as number,
          data: error as ErrorObjectType,
        }),
      })
    );
    dispatch(setTenantWebSettings({ tntWebSettingsData: [] }));
  }
};
export const getTenantsGeneralSetApi = (tenantID): AppThunk => async (
  dispatch
) => {
  try {
    const res = await userApi.post(ApiConstants.getTenantGenSet, {
      tenantID: tenantID,
    });

    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setTenantGeneralSettings({ tntGeneralSettings: res.data }));
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(getTenantsGeneralSetApi(tenantID)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setTenantGeneralSettings({ tntGeneralSettings: [] }));
    }
  } catch (error) {
    dispatch(
      setApiResponse({
        status: error as number,
        data: getCustomAPIErrorMessage({
          status: error as number,
          data: error as ErrorObjectType,
        }),
      })
    );
    dispatch(setTenantGeneralSettings({ tntGeneralSettings: [] }));
  }
};

export const getTenantsListfromApi = (): AppThunk => (dispatch) => {
  userApi.get(ApiConstants.getTenantsListAPI).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getTenantList({ tenants: res.data }));
    } else {
      const callback = () => dispatch(regfeshTokenId(getTenantsListfromApi()));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(getTenantList({ tenants: [] }));
    }
  });
};

export const getTenantsTermsListfromApi = (): AppThunk => (dispatch) => {
  dispatch(loadingTenantTermsList(true));
  userApi
    .get(ApiConstants.getTenantsTermsListAPI)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(getTenantTermsList({ tenantsTerms: res.data }));
      } else {
        const callback = () =>
          dispatch(regfeshTokenId(getTenantsTermsListfromApi()));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        dispatch(getTenantTermsList({ tenantsTerms: [] }));
      }
    })
    .finally(() => {
      dispatch(loadingTenantTermsList(false));
    });
};

export const uploadTermsApi = (data, tenantId): AppThunk => (dispatch) => {
  delete uploadApi.headers["TenantID"];
  uploadApi.setHeader("TenantID", tenantId);
  uploadApi.post(ApiConstants.uploadTermsAPI, data).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(uploadTermsCreated(true));
      setTimeout(function() {
        dispatch(uploadTermsCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(uploadTermsApi(data, tenantId)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};
export const downloadTermsApi = (
  tenantId,
  languageCode,
  versionnumber,
  fileName
): AppThunk => (dispatch) => {
  let parameters;
  let url;

  parameters = `tenantId=${tenantId}&languageCode=${languageCode}&versionnumber=${versionnumber}&consentType=tnc`;
  url = ApiConstants.downloadTermsAPI;

  downloadreportApi.get(url + parameters).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(downloadTermsCreated(true));
      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      //Append to html page
      document.body.appendChild(link);
      //Force download
      link.click();
      //Clean up and remove the link
      link.remove();
      setTimeout(function() {
        dispatch(downloadTermsCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.DOWNLOAD })
      );
    } else {
      const callback = () =>
        dispatch(
          regfeshTokenId(
            downloadTermsApi(tenantId, languageCode, versionnumber, fileName)
          )
        );
      handleUnauthorized(res.status, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};
export const deletProvider = (uids: any): AppThunk => (dispatch) => {
  dispatch(getTenantList({ tenants: null }));
  userApi.delete(`/api/v1/tenant`, uids).then((res) => {
    dispatch(getTenantsListfromApi());
  });
};
export const createGeneralSettingsTenant = (
  body: TenantGeneralSettingsModel,
  history: History
): AppThunk => async (dispatch) => {
  dispatch(loadingApiData(true));
  try {
    const res = await userApi.post(ApiConstants.createTenantGenSet, body);
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
      (history as History).push(`${editGeneralSettingsPath}/${body?.tenantID}`);
    } else {
      const callback = () =>
      dispatch(regfeshTokenId(createGeneralSettingsTenant(body,history)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  } catch (error) {
    dispatch(
      setApiResponse({
        status: error as number,
        data: getCustomAPIErrorMessage({
          status: error as number,
          data: error as ErrorObjectType,
        }),
      })
    );
  }
  dispatch(loadingApiData(false));
};
export const updateGeneralSettingsTenant = (
  body: TenantGeneralSettingsModel
): AppThunk => async (dispatch) => {
  dispatch(loadingApiData(true));
  userApi.post(ApiConstants.updateTenantGenSet, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getTenantByIdData(body?.tenantID));
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(updateGeneralSettingsTenant(body)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  }).finally(() => {dispatch(loadingApiData(false))});
};

export const updateWebSettingsTenant = (body: any): AppThunk => async (
  dispatch
) => {
  dispatch(loadingApiData(true));
  userApi.post(ApiConstants.updateTenantWebSet, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(updateWebSettingsTenant(body)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  }).finally(() => {dispatch(loadingApiData(false))});
};
export const getTenantAppSetApi = (tenantID): AppThunk => async (dispatch) => {
  try {
    dispatch(loadingApiData(true));
    dispatch(setTenantAppSettings({ tntAppSettingsData: [] }));
    const res = await userApi.post(ApiConstants.getTenantAppSet, {
      tenantID: tenantID,
    }).finally(() => {dispatch(loadingApiData(false))});
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setTenantAppSettings({ tntAppSettingsData: res.data }));
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(getTenantAppSetApi(tenantID)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setTenantAppSettings({ tntAppSettingsData: [] }));
    }
  } catch (error) {
    dispatch(
      setApiResponse({
        status: error as number,
        data: getCustomAPIErrorMessage({
          status: error as number,
          data: error as ErrorObjectType,
        }),
      })
    );
    dispatch(setTenantAppSettings({ tntAppSettingsData: [] }));
  }
};
export const updateAppSettingsTenant = (body: any): AppThunk => async (
  dispatch
) => {
  dispatch(loadingApiData(true));
  userApi.post(ApiConstants.updateTenantAppSet, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(tenantCreated(true));
      setTimeout(function() {
        dispatch(tenantCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(updateGeneralSettingsTenant(body)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  }).finally(() => {dispatch(loadingApiData(false))});
};


export const getAppKeySecretFromAPI = (tenantId: string): AppThunk => (
  dispatch
) => {
  dispatch(loadingAppKeyAndSecret(true));
  userApi
    .get(`${ApiConstants.getAppKeyAndSecretAPI}/${tenantId}`)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setAppKeyAndSecret(res.data));
      } else {
        dispatch(setAppKeyAndSecret({}));
      }
      // else {
      //   const callback = () => dispatch(regfeshTokenId(getAppKeySecretFromAPI(tenantId)));
      //   handleUnauthorized(res.status as number, callback);
      //   dispatch(
      //     setApiResponse({
      //       status: res.status,
      //       data: getCustomAPIErrorMessage({
      //         status: res.status as number,
      //         data: res.data as ErrorObjectType,
      //       }),
      //     })
      //   );
      //   dispatch(setAppKeyAndSecret({}));
      // }
    })
    .finally(() => {
      dispatch(loadingAppKeyAndSecret(false));
    });
};

export const generateAppKeySecretFromAPI = (tenantId: string): AppThunk => (
  dispatch
) => {
  dispatch(loadingAppKeyAndSecret(true));
  userApi
    .put(`${ApiConstants.generateAppKeyAndSecretAPI}/${tenantId}`)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setAppKeyAndSecret(res.data));
      } else {
        const callback = () =>
          dispatch(regfeshTokenId(getAppKeySecretFromAPI(tenantId)));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        dispatch(setAppKeyAndSecret({}));
      }
    })
    .finally(() => {
      dispatch(loadingAppKeyAndSecret(false));
    });
};
export const getTenantByIdData = (tenantId: string): AppThunk => (
  dispatch
) => {
  dispatch(setTenantById({}));
  userApi
    .get(`${ApiConstants.getTenantConfigAPI}/${tenantId}`)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
         dispatch(setTenantById({ tntByIdData: res?.data[0]?.tenant }));
      } else {
        const callback = () =>
          dispatch(regfeshTokenId(getTenantByIdData(tenantId)));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
 };

export const selectTenants = (state: RootState) => state.tenants.tenantsData;
export const tenantCreatedStatus = (state: RootState) =>
  state.tenants.isTenantCreated;
export const tenantCreatedError = (state: RootState) => state.tenants.error;
export const getCreatedTenant = (state: RootState) =>
  state.tenants.createdTenant;
export const selectTenantsTerms = (state: RootState) =>
  state.tenants.tenantsTermsData;
export const termsCreatedStatus = (state: RootState) =>
  state.tenants.isUploadTerms;
export const downloadStatus = (state: RootState) =>
  state.tenants.isDownloadTerms;
export const termsCreatedError = (state: RootState) => state.tenants.error;
export const getLoadingTenantTermsList = (state: RootState) =>
  state.tenants.isLoading;
export const getAppKeyAndSecret = (state: RootState) =>
  state.tenants.appKeyAndSecret;
export const appKeyAndSecretLoading = (state: RootState) =>
  state.tenants.loadingAppKeyAndSecret;
export const tenantGeneralSettingsData = (state: RootState) =>
  state.tenants.tenantGeneralSettingsData;
export const tenantWebSettingsData = (state: RootState) =>
  state.tenants.tenantWebSettingsData;
export const tenantAppSettingsData = (state: RootState) =>
  state.tenants.tenantAppSettingsData;
export const tenantByIdData = (state: RootState) => state.tenants.tenantByIdData;
export const getLoadingAPIData = (state: RootState) =>
         state.tenants.isLoadingAPIData;

export default tenantSlice.reducer;
