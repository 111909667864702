import React from "react";
import { HeaderHooks } from "../Hooks/Header.Hooks";
import { TenentDropDown } from "./DropDown/TenentDropDown";
import * as RouteContants from "constants/routeConstants";
import { ProviderDropDown } from "./DropDown/ProviderDropDown";
import {
  CSOnlyView,
  AdminOnlyView,
  ProviderOnlyView,
} from "components/RoleHook/RoleHook";
import { ChannelDropDown } from "./DropDown/ChannelDropdown";

const DropDownComponent = () => {
  const {
    pathname,
    getProviderValues,
    reviews: { reviewsFilterParamChannel },
  } = HeaderHooks();

  let renderContent: JSX.Element | null = null;

  const CSOnlyViewWithTenentDropDown = (
    <CSOnlyView>
      <TenentDropDown />
    </CSOnlyView>
  );

  switch (pathname) {
    case RouteContants.channelsPath:
      renderContent = (
        <>
          <CSOnlyView>
            <ProviderDropDown />
          </CSOnlyView>
          <AdminOnlyView>
            <ProviderDropDown />
          </AdminOnlyView>
        </>
      );
      break;
    case RouteContants.scheduleTitles:
    case RouteContants.collectionsPath:
      renderContent = CSOnlyViewWithTenentDropDown;
      break;
    case RouteContants.contentTitles:
      renderContent = (
        <>
          {CSOnlyViewWithTenentDropDown}
          <ProviderOnlyView>
            <ChannelDropDown />
          </ProviderOnlyView>
        </>
      );
      break;
    case RouteContants.reviews:
      renderContent = (
        <>
          <AdminOnlyView>
            <ChannelDropDown
              from="reviews"
              isDisabled={getProviderValues() !== ""}
            />
            <ProviderDropDown
              from="reviews"
              isDisabled={
                reviewsFilterParamChannel && reviewsFilterParamChannel !== ""
              }
            />
          </AdminOnlyView>
          {CSOnlyViewWithTenentDropDown}
        </>
      );
      break;
    case RouteContants.subscriptionsPath:
    case RouteContants.userManagement:
    case RouteContants.userReportPath:
    case RouteContants.socialLinks:
    case RouteContants.appLinks:
    case RouteContants.customLinks:
    case RouteContants.documentsPath:
    case RouteContants.categoriesPath:
    case RouteContants.bulkUploadPath:
    case RouteContants.generalSiteSettingsPath:
    case RouteContants.webSiteSettingsPath:
    case RouteContants.appSiteSettingsPath:
      renderContent = CSOnlyViewWithTenentDropDown;
      break;
    default:
      break;
  }

  return <div className="drop-down-container">{renderContent}</div>;
};

DropDownComponent.propTypes = {};

export { DropDownComponent };