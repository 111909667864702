import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";

const lightColor = "rgba(255, 255, 255, 0.7)";

export const HeaderComponentStyles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  });

export const PaperHeaderComponentStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 14,
    paddingBottom: 14,
    borderBottom: "solid 1px #e0e0e0",
  },
   iconText: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.09,
      marginRight: 5,
      color: "#36dcb6",
      cursor: "pointer",
      marginLeft:5,
    },
  headerLable: {
    //height:50,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 24,
    borderBottom: "solid 1px #e0e0e0",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 14,
  },
  siteSettingTopHeader: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 24,
    borderBottom: "solid 1px #e0e0e0",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 14,
  },
   settingsButtonText: {
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.69,
      letterSpacing: "0.3px",
      textAlign: "right",
      color: "#8e8e93",
      cursor: "pointer",
      
    },
    visitText: {
      cursor: "pointer",
    },
    visitTextDisable: {
      cursor: "not-allowed",
    },
    visitTextBefore:{
      paddingLeft:22,
    },
    iconActionNewOpen: {
        cursor: "pointer",
      '&::after': {
      content: '""',
      width: '1px',
      height: '30px',
      margin: '0 4px 2px 7px',
      border: 'solid 1px #e0e0e0',
      display: 'inline-flex',
      cursor: "pointer",
    },
      width: "16px",
      height: "16px",
      margin: "7px 6px",
      objectFit: "contain",
    },
    iconActionHelp: {
      width: "31.7px",
      height: "31.7px",
    },
    iconVisitSite: {
      width: "32px",
      height: "32px",
      objectFit: "contain",
      marginLeft: 5,
       marginTop: 5,
        cursor: "pointer",
    }
}));

export const PageHeaderComponentStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: 250,
    marginTop: 8,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 196,
    borderBottom: "none",
  },

  lable: {
    color: "#000",
  },
  select: {
    borderBottom: "#fff",
  },
  secondaryBar: {
    zIndex: 1,
    height: 86,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbarSelect: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  menuButton: {
    marginLeft: -theme.spacing(1),
  },

  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  header: {
    height: 56,
  },
  button: {
    borderColor: lightColor,
  },
  tabBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  providerSelect: {
    margin: "-4px 45px 0px 0px !important",
    width: "196px",
  },
}));

export interface HeaderComponentProps
  extends WithStyles<typeof HeaderComponentStyles> {
  onDrawerToggle: () => void;
}
