import { THUMBNAIL_TYPE } from "constants/enums";
import { orderBy, find, pick, toString, isEmpty, map, compact } from "lodash";
import {
  getEquipment,
  getfocusArea,
  getIntensityValue,
  getLanguageFromConfig,
  getSkillLableValue,
} from "utils/utilityFunctions";

export const videoDefaulState = (videoState: any = {}) => {
  return {
    name: videoState?.className ?? "",
    providerId: videoState?.providerID ?? "",
    provider: videoState?.provider ?? "",
    videoStatus: videoState?.videoStatus ?? 0,
    description: "",
    tag: videoState?.tag ?? "",
    tenants: [],
    instructorName: "",
    channel: "",
    channelName: "",
    skillLevel: "",
    focusAreas: [] as any,
    focusAreaTags: [] as any,
    videoLanguage: "",
    category: "",
    subCategory: "",
    subCategoryData: [] as any,
    equipments: [] as any,
    equipmentTypeTags: [] as any,
    intensity: "",
    labels: [] as any,
    releaseDate: "",
    video: "",
    trailerWeb: "",
    cover: "",
    trailerMobile: "",
    trailerName: "",
    trailerStatus: 0,
    level: "",
    // status: 0,
    alternateLink: "",
    durationSecond: 0,
    creationDate: "",
    classLanguageCode: "" as any,
    endDate: "" as any,
    isActive: false,
    isEquipment: false,
    classCategoryId: "",
    classSubCategoryId: "",
    videoStatusPrimary: videoState?.videoStatusPrimary ?? 0,
    thumbnailType: videoState?.thumbnailType ?? THUMBNAIL_TYPE.AUTO,
  };
};

export const setFormDataUtil = (
  channelDetail?: any,
  optionalChannelData: string = "",
  metaData?: any
) => {
  const categories = find(metaData?.categories, {
    category: channelDetail?.classCategory,
  });
  console.log(channelDetail?.thumbnailType,'channelDetail?.thumbnailType');
  let newFormState = {
    name: channelDetail?.className,
    providerId: channelDetail?.providerID,
    provider: channelDetail?.provider,
    description: channelDetail?.classDescription,
    tag: channelDetail?.tag,
    tenants: channelDetail?.tenants,
    instructorName: channelDetail?.instructor,
    channel:
      optionalChannelData !== ""
        ? optionalChannelData
        : channelDetail?.channelId,
    channelName: channelDetail?.channelName ?? "",
    skillLevel: getSkillLableValue(channelDetail?.skill),
    focusAreas: getfocusArea(
      channelDetail?.focusAreaTags,
      metaData?.focusAreas
    ),
    focusAreaTags:
      channelDetail?.focusAreaTags !== "" ? channelDetail?.focusAreaTags : "",
    videoLanguage: channelDetail?.classLanguage,
    category: channelDetail?.classCategory,
    subCategory: channelDetail?.classSubCategory,
    subCategoryData: orderBy(categories?.subCategories ?? [], [], "asc"),
    equipments: getEquipment(
      channelDetail?.equipmentTypeTags,
      metaData?.equipments
    ),
    equipmentTypeTags:
      channelDetail?.equipmentTypeTags !== ""
        ? channelDetail?.equipmentTypeTags
        : "",
    intensity: getIntensityValue(channelDetail?.intensity),
    labels: channelDetail?.labels,
    releaseDate: channelDetail?.scheduleDate,
    video: "",
    videoStatus: channelDetail?.videoStatus,
    trailerWeb: channelDetail?.trailerLinkWeb,
    trailerMobile: channelDetail?.trailerLinkMobile,
    trailerName: channelDetail?.trailerName,
    trailerStatus: channelDetail?.trailerStatus,
    cover: channelDetail?.imageLink,
    level: channelDetail?.level,
    // status: channelDetail?.status,
    alternateLink: channelDetail?.alternateLink,
    durationSecond: channelDetail?.durationSecond,
    creationDate: channelDetail?.creationDate,
    classLanguageCode: channelDetail?.classLanguageCode,
    endDate: channelDetail?.endDate,
    isActive: channelDetail?.endDate,
    isEquipment: channelDetail?.isEquipment,
    classCategoryId: channelDetail.classCategoryId,
    classSubCategoryId: channelDetail?.classSubCategoryId,
    videoStatusPrimary: channelDetail?.videoStatusPrimary ?? 0,
    thumbnailType: channelDetail?.thumbnailType ?? THUMBNAIL_TYPE.CUSTOM,
  };
  return newFormState;
};

export const isAutoPublish = (fd) => {
  let keys = [
    // 'classCategory',
    "classCategoryId",
    "category",
    // 'classDescription',
    "cover",
    "description",
    // 'classLanguage',
    "classLanguageCode",
    // 'className',
    "name",
    // "equipments",
    // 'imageLink',
    // "intensity",
    // 'level',
    "provider",
    // 'skill',
    // "skillLevel",
    "channelName",
    "videoLanguage",
    "releaseDate",
    // 'focusArea',
    // 'instructor',
    // 'labels',
  ];

  // if (fd?.subCategoryData.length > 0) {
  //   keys = [...keys, "subCategory"];
  // }
  const res = compact(map(pick(fd, keys), (_: any) => isEmpty(toString(_))));

  return !res.length;
};
export const enableCkEditor = (user, userRoleEnums, tenantData) => {
  let contentRichTextEnable = tenantData?.contentRichTextEnable ?? tenantData;
  if (
    user.role === userRoleEnums.PRVIDER_USER ||
    user.role === userRoleEnums.ADMIN_USER
  ) {
    return false;
  } else if (
    (user.role === userRoleEnums.CS_USER ||
      user.role === userRoleEnums.TENANT_AS_PROVIDER_USER) &&
    contentRichTextEnable
  )
    return true;
  else return false;
};

export const getOptionalPlaceholder = (
  placeholder: string | labelTypes
): string => {
  const lang = getLanguageFromConfig();
  if (placeholder && typeof placeholder !== "string") {
    const translatedId1 = placeholder.props.id.split(".")[0];
    const translatedId2 = placeholder.props.id.split(".")[1];
    return `${lang[translatedId1][translatedId2]} (Optional)`;
  }
  return `${placeholder || ""} (Optional)`;
};

type labelTypes = {
  props: {
    id: string;
  };
};

export const getOptionalLabel = (label: string | labelTypes) => {
  const lang = getLanguageFromConfig();
  if (label && typeof label !== "string") {
    const translatedId1 = label.props.id.split(".")[0];
    const translatedId2 = label.props.id.split(".")[1];
    return `${lang[translatedId1][translatedId2]} (Optional)`;
  }
  return `${label || ""} (Optional)`;
};
