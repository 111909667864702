import React, { useState, useEffect, Fragment } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
/* eslint-disable no-use-before-define */
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { MultiSelectComponentStyles } from "style/components";
import TooltipInfo from "components/Notification/TooltipInfo";
import Translate from "utils/translate";
import { Skeleton } from "@material-ui/lab";
import { getOptionalLabel } from "components/Forms/FormMetaDataUtility";

export default function MultiSelect(props) {
  const classes = MultiSelectComponentStyles();
  const {
    options,
    selectCallback = () => {},
    values = [],
    lable = "Select Lable",
    optional = false,
    isArray = false,
    multiple = true,
    disableClearable = false,
    disabled = false,
    event,
    loading,
    limitCount = 3
  } = props;
  const [data, setData] = useState(null as any);
  const [isPrefilled, setPrefilled] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(null as any);

  const handleChange = (event: any, value: any | null) => {
    if (multiple) {
      if (value && value.length > 0) {
        if (setDisplayOptions.length < limitCount) {
          setDisplayOptions(options);
        }
        if (value.length === limitCount) {
          setDisplayOptions([]);
        }
        if (isPrefilled && data && data.length > 0) {
          if (value.length <= limitCount) {
            multiple ? selectCallback([...value]) : selectCallback(value[0]);
          }
          setPrefilled(false);
        } else {
          if (value.length <= limitCount) {
            multiple ? selectCallback([...value]) : selectCallback(value[0]);
          }
        }
      } else {
        selectCallback([]);
        setData(null);
        setDisplayOptions(options);
      }
    } else {
    
      setDisplayOptions(options);
      setData(null);
      selectCallback(value);
    }
  };

  useEffect(() => {
    setPrefilled(true);
  }, []);

  useEffect(() => {
    if (multiple) {
      if (values && values.length > 0) {
        if (values.length <= limitCount) {
          setData(values);
          setDisplayOptions(options);
        }
        if (values.length === limitCount) {
          setDisplayOptions([]);
        }
      }
    } else {
      setData(values);
    }
  }, [values]);

  useEffect(() => {
    if (options && options.length > 0) {
      setDisplayOptions(options);
    } else setDisplayOptions([]);
  }, [options]);

  if (loading) {
    return (
      <div className={`${props.style ?? classes.formControl} defaultInput`}>
        <Skeleton variant="rect" animation="pulse" width="100%" height={53} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {displayOptions && (
        <FormControl className={props.style ?? classes.formControl}>
          {options && (
            <React.Fragment>
              {data ? (
                <Autocomplete
                  disabled={disabled}
                  limitTags={multiple ? limitCount : 1}
                  disableClearable={disableClearable}
                  multiple={multiple}
                  placeholder={`Only ${
                    multiple ? limitCount : "1"
                  } can be selected`}
                  noOptionsText={`Only ${
                    multiple ? limitCount + " options" : "1 option"
                  } can be selected`}
                  onChange={handleChange}
                  id="tags-standard"
                  options={displayOptions}
                  renderOption={
                    !isArray
                      ? (option) => <Fragment>{option?.name ?? ""}</Fragment>
                      : (option) => <Fragment>{option}</Fragment>
                  }
                  getOptionLabel={
                    !isArray ? (option) => option?.name : (option) => option
                  }
                  getOptionSelected={(option, value) => {
                    if (!isArray) {
                      if (value === null || option === null) return false;
                      if (value.name && option.name) {
                        return (
                          option.name.toLowerCase() ===
                            value.name.toLowerCase() ||
                          option.value === value.value
                        );
                      } else {
                        return option === value;
                      }
                    } else {
                      return option === value;
                    }
                  }}
                  value={data}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={optional ? getOptionalLabel(lable) : lable}
                    />
                  )}
                />
              ) : (
                <React.Fragment>
                  <div />
                  <Autocomplete
                    disabled={disabled}
                    multiple={multiple}
                    placeholder={`Only ${
                      multiple ? limitCount : "1"
                    } can be selected`}
                    noOptionsText={`Only ${
                      multiple ? limitCount+" options" : "1 option"
                    } can be selected`}
                    onChange={handleChange}
                    id="tags-standard"
                    options={displayOptions}
                    renderOption={
                      !isArray
                        ? (option: any) => (
                            <Fragment>{option?.name ?? ""}</Fragment>
                          )
                        : (option) => <Fragment>{option}</Fragment>
                    }
                    getOptionLabel={
                      !isArray
                        ? (option: any) => option?.name ?? ""
                        : (option: any) => option
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={optional ? getOptionalLabel(lable) : lable}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </FormControl>
      )}
      {event && (
        <TooltipInfo
          title={Translate({
            id: "Schedule.eventDurationText",
          })}
          isEvent={event}
        />
      )}
    </div>
  );
}
