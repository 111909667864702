import { makeStyles } from "@material-ui/core/styles";
import { FormPaddingCommonStyle, FormCommonStyle } from "./Common.style";
import * as ThemeConstant from "constants/themeConstants";
export const TenantCreationFormStyle = makeStyles((theme) => ({
  ...{...FormCommonStyle(theme)},
  ...FormPaddingCommonStyle,
  apiKeyInputBox: {
    margin: "12px 0px 0px 0px",
  },
  formControlCheckbox: {
    margin: "27px 0px 8px 0px",
    width: "100%",
  },
  formCheckbox: {
    margin: "0px !important",
    width: "100%",
  },
  formCheckboxActive:{
    margin: "12px 0px 0px 0px",
    width: "100%",
  },
  checkboxSpacing: {
    marginTop: "27px",
  },
  generateKeys: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginTop: 16,
  },
  notGenerateKeys: {
    color: ThemeConstant.textColorDarkDesabled,
    cursor: "none",
    marginTop: 16,
  },
  inputWithCopy: {
    display: 'flex',
  },
  copyContainer: {
    position: 'relative',
  },
  copyImg: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 12,
    right: 0,
  },
 siteType: {
    fontSize: "12px",
    lineHeight: "1",
    marginLeft: "3px",
     marginTop: "20px",
  },
  siteTypeRadio: {
    width: "39px",
    margin: "13px 33.5px 0px 0px !important",
    fontSize: "16px",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    height: "30",
  },
  lockRadio: {
    margin: "13px 30px 10px 0px !important",
    fontSize: "16px",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    height: "30",
  },
  lockBehaviour: {
    margin: "20px 2px 10px 5px !important",
    fontSize: "12px",
    lineHeight: "1",
  },
  wexerCategories: {
    fontSize: "12px",
    lineHeight: "1",
    marginTop: "17px"
  },
  marketingConsent: {
    marginTop: "24px",
    fontSize: "12px",
    lineHeight: "1",
  },
  siteTypeLabel: {
    marginLeft: "3px",
    cursor:"pointer",
  },
  allowedSubscription: {
    margin: "28px 169px 14px 0px !important",
    fontSize: "12px",
    lineHeight: "1",
  },
   tabBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    "& span": {
      textTransform: "uppercase !important",
    },
    margin: "11px 30px 2px 0px",
  },
}));

export const TenantDownloadUploadStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignSelf: "flexStart",
    marginTop: 10,
    color: "#263238",
    fontSize: 12,
    fontFamily: "Roboto",
    lineHeight: "12px",
    letterSpacing: "0.4px",
  },
  errorMessage: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "normal",
    lineHeight: 1.43,
    paddingTop: "34px",
    fontStretch: "normal",
    letterSpacing: "0.15px",
  },
  replace: {
    color: "#36dcb6",
    cursor: "pointer",
    marginTop: 10,
    fontSize: 16,
    fontFamily: "Roboto",
    lineHeight: "24px",
    letterSpacing: "0.09px",
  },
  replaceFile: {
    color: "#263238",
    marginTop: 10,
    fontSize: 16,
    fontFamily: "Roboto",
    lineHeight: "24px",
    letterSpacing: "0.09px",
  },
  input: {
    display: "none",
    marginLeft: 10,
  },
  select: {
    color: "#36dcb6",
    cursor: "pointer",
    display: "flex",
    alignSelf: "flexStart",
    marginTop: 10,
    fontSize: 16,
    fontFamily: "Roboto",
    lineHeight: "24px",
    letterSpacing: "0.09px",
  },
  line: {
    width: '100%',
    height: 1,
    margin: "12px 0 0",
    border: "solid 1px rgba(0, 0, 0, 0.42)",
  },
}));
