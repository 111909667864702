import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { apiResponseEnums,  USER_MESSAGES } from "constants/enums";
import {
  GetApiMobileAppSiteSettingsPayload,
  MobileAppSiteSettingsPayload,
} from "models/SiteSettings/MobileAppSiteSettingsModel";
import axios from 'axios'; // If you prefer axios
 
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";

interface MobileAppSiteSettingsState {
  error: number;
  pageLoading: boolean;
  MobileAppSiteSettingsData: MobileAppSiteSettingsPayload | null;
}

const initialState: MobileAppSiteSettingsState = {
  error: 0,
  pageLoading: false,
  MobileAppSiteSettingsData: null,
};
export const MobileAppSiteSettingsSlice = createSlice({
  name: "mobileAppSettings",
  initialState,
  reducers: {
    setMobileAppSiteSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    setMobileAppSiteSettingsList: (
      state,
      action: PayloadAction<MobileAppSiteSettingsPayload | null>
    ) => {
      state.MobileAppSiteSettingsData = action.payload;
    },
    setApiError: (state, action: PayloadAction<number>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setApiError,
  setMobileAppSiteSettingsLoading,
  setMobileAppSiteSettingsList,
} = MobileAppSiteSettingsSlice.actions;

export const updateMobileAppSiteSettings = (
  body: MobileAppSiteSettingsPayload
): AppThunk => (dispatch) => {
  dispatch(setMobileAppSiteSettingsLoading(true));
  userApi.post(ApiConstants.updateMobileAppSiteSettingsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_MESSAGES.SAVED,
        })
      );
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setMobileAppSiteSettingsLoading(false));
  });
};

export const getMobileAppSiteSettingsListFromApi = (
  body: GetApiMobileAppSiteSettingsPayload
): AppThunk => (dispatch) => {
  dispatch(setMobileAppSiteSettingsList(null));
  dispatch(setMobileAppSiteSettingsLoading(true));
  userApi.post(ApiConstants.getMobileAppSiteSettingsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      let response = res.data as MobileAppSiteSettingsPayload;
        dispatch(setMobileAppSiteSettingsList(response));
    } else {
      dispatch(setMobileAppSiteSettingsList(null));
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setMobileAppSiteSettingsLoading(false));
  });
};
export const isLoadingData = (state: RootState) =>
         state.mobileAppSettings?.pageLoading;
export const generalSiteSettingsCreatedOrNot = (state: RootState) =>
         state.mobileAppSettings?.error;
export const selectMobileAppSiteSettings = (state: RootState) =>
         state.mobileAppSettings?.MobileAppSiteSettingsData;
export default MobileAppSiteSettingsSlice.reducer;
