import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import {
  emailValidation,
  nameValidation,
  passwordValidation,
  securePasswordValidation,
} from "utils/validation";
import {
  appKeyAndSecretLoading,
  createGeneralSettingsTenant,
  generateAppKeySecretFromAPI,
  getAppKeyAndSecret,
  getLoadingAPIData,
  updateGeneralSettingsTenant,
} from "_pages/tenants/tenantSlice";
import { isEmpty, omit } from "lodash";
import {
  TENANT_ID_LENGTH,
  ACTIVATED_PRODUCT_TYPES,
  findLanguageTagsByName,
  findLanguageNameByTag,
  TENANT_MESSAGES,
  ACTIVATED_PRODUCT_TYPES_OBJECTS,
} from "constants/enums";
import { useHistory } from "react-router";
import {
  TenantGeneralSettingsModel,
  FormData,
} from "models/Tenants/TenantsGeneralSettingsModel";
import { isEqual } from "lodash";
interface FormProps {
  callback: () => void; // replace with the actual type
  isCSUser: boolean;
  generalSettings?: any; // replace with the actual type
  tenantID?: string; // replace with the actual type
  tntByIdData?: any; // replace with the actual type
  edit?: boolean;
}

export const useTenantGeneralSettingsForm = (props: FormProps) => {
  const dispatch = useDispatch();
  const apiDataLoading = useSelector<boolean>(getLoadingAPIData);
  const history = useHistory();
  const initialFormData: FormData = {
    name: "",
    tenantId: "",
    customerNumber: "",
    timezone: "",
    offset: "",
    isProviderActive: 0, // set true for tenant as provider
    email: "",
    password: "",
    providerId: "",
    mediaPlatform: 0,
    jwPlayerSiteId: "",
    jwPlayerSecret: "",
    audioNormalize: false,
    LanguageCodes: [],
    LanguageTag: "",
    web: false,
    api: false,
    virtual: false,
    app: false,
  };

  const [formData, setFormData] = useState<FormData>({ ...initialFormData });
  const [errors, setError] = useState<FormData>({ ...initialFormData });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inEditMode, setInEditModel] = useState<boolean>(false);
  const [isEmailPasswordEditable, setEmailPasswordEditable] = useState<boolean>(
    true
  );
  const [isFormDataLoading, setFormDataLoading] = useState(!!props.edit);
  const [modal, setModal] = useState<boolean>(false);
  const appKeyAndSecret = useSelector(getAppKeyAndSecret);
  const appKeySecretLoading = useSelector(appKeyAndSecretLoading);
  const [selectedTenantId, setSelectedTenantId] = useState<string>("");
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [apiData, setFromAPIData] = useState<FormData>({ ...initialFormData });
  const [compareData, setCompareFormData] = useState<FormData>({
    ...initialFormData,
  });
  const [isSameFormData, setIsSameFormData] = useState(false);
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);
  const [tabRoutingPath, setTabRoutingPath] = useState<string>("");
  useEffect(() => {
    return () =>
      setFormData({
        ...initialFormData,
      });
  }, []);

  const generateLowercaseId = (input: string) => {
    let id = input.split(" ").join("");
    return id.toLowerCase().slice(0, TENANT_ID_LENGTH.LIMIT);
  };

  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (formData?.name?.length > 0 && !inEditMode) {
      setFormData({
        ...formData,
        tenantId: generateLowercaseId(formData.name),
      });
    }
  }, [formData.name]);

  const generateAppKeySecret = () => {
    dispatch(generateAppKeySecretFromAPI(formData.tenantId));
    setModal(false);
  };
  const showModalBox = (routingPath) => {
    setUnsavedModal(true);
    setTabRoutingPath(routingPath);
  };
  useEffect(() => {
    let apiObjectData = omit(apiData, "password");
    let formObjectData = omit(formData, "password");
    if (isFormDataChanged === true && apiObjectData?.tenantId !== "") {
      if (isEqual(apiObjectData, formObjectData)) {
        setIsSameFormData(false);
      } else {
        setIsSameFormData(true);
      }
    } else {
      setIsSameFormData(false);
    }
  }, [isFormDataChanged, formData]);

  const handleUnsavedModalOkClick = () => {
    history.push(tabRoutingPath);
  };

  const handleUnsavedModalClose = () => {
    setUnsavedModal(false);
  };
  useEffect(() => {
    if (formData?.tenantId?.length > 0) {
      setFormData({
        ...formData,
        tenantId: generateLowercaseId(formData.tenantId),
      });
    }
  }, [formData.tenantId]);

  useEffect(() => {
    if (apiDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [props.generalSettings, apiDataLoading]);

  useEffect(() => {
    const { generalSettings } = props;

    if (!generalSettings) {
      setInEditModel(false);
      setEmailPasswordEditable(true);
      return;
    }

    const {
      timeZoneInfo,
      languageCodes,
      name,
      tenantID,
      email,
      customerNumber,
      audioNormalize,
      activatedProducts,
      isProviderActive,
      providerId,
      mediaPlatform,
      jwPlayerSiteId,
      jwPlayerSecret,
      languageTag,
      providerEmail,
    } = generalSettings;

    const offsetName = timeZoneInfo?.offsetName;
    const utcOffset = timeZoneInfo?.utcOffset;
    
    let locales = languageCodes;
    locales = locales && locales.map((locale) => locale.toLowerCase());
    
    const lcode = findLanguageNameByTag(locales);
    const ltag = languageTag?.toLowerCase();

    const commonData = {
      name,
      tenantId: tenantID,
      email,
      customerNumber,
      audioNormalize,
      web: activatedProducts?.includes(ACTIVATED_PRODUCT_TYPES_OBJECTS.web),
      api: activatedProducts?.includes(ACTIVATED_PRODUCT_TYPES_OBJECTS.api),
      app: activatedProducts?.includes(ACTIVATED_PRODUCT_TYPES_OBJECTS.app),
      virtual: activatedProducts?.includes(
        ACTIVATED_PRODUCT_TYPES_OBJECTS.virtual
      ),
      isProviderActive,
      providerId,
      mediaPlatform,
      jwPlayerSiteId,
      jwPlayerSecret,
      LanguageCodes: lcode,
      LanguageTag: findLanguageNameByTag(ltag)?.[0],
      password: "P@ssw0rd", // this is just to show some character in field
      timezone: offsetName,
      offset: utcOffset,
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      ...commonData,
    }));
    setFromAPIData(commonData);
    setInEditModel(true);
    setFormDataLoading(false);
    setEmailPasswordEditable(!email);
  }, [props.generalSettings]);

  const getErrorText = (stateData: any = false) => {
    let frmData: any = stateData ? stateData : formData;
    let isValidTimeZone = frmData.offset !== "" && frmData.timezone !== "";
    let errObj: any = {};

    if (!nameValidation(frmData.name)) {
      errObj.name = TENANT_MESSAGES.NAME_REQUIRED;
    }
    if (!nameValidation(frmData.tenantId)) {
      errObj.tenantId = TENANT_MESSAGES.TENANT_ID_REQUIRED;
    }
    if (!nameValidation(frmData.customerNumber)) {
      errObj.customerNumber = TENANT_MESSAGES.CUSTOMER_NUMBER_REQUIRED;
    }
    if (!frmData?.web && !frmData?.app && !frmData?.virtual && !frmData?.api) {
      errObj.activateProducts = TENANT_MESSAGES.ACTIVATE_PRODUCTS_REQUIRED;
    }
    if (!isValidTimeZone) {
      errObj.timezone = TENANT_MESSAGES.TIMEZONE_REQUIRED;
    }
    if (frmData.LanguageCodes.length === 0) {
      errObj.LanguageCodes = TENANT_MESSAGES.SUPPORTED_LANGUAGES_REQUIRED;
    }
    if (isEmpty(frmData.LanguageTag)) {
      errObj.LanguageTag = TENANT_MESSAGES.DEFAULT_LANGUAGE_REQUIRED;
    }
    if (frmData?.isProviderActive) {
      const isEmailValid = emailValidation(frmData.email);
      const isJWPlayerSiteId = isEmpty(frmData?.jwPlayerSiteId);
      const isJWPlayerSecret = isEmpty(frmData?.jwPlayerSecret);
      const isPasswordValid = inEditMode
        ? passwordValidation(frmData.password)
        : securePasswordValidation(frmData.password);

      if (!isEmailValid) {
        errObj.email = TENANT_MESSAGES.EMAIL_REQUIRED;
      }
      if (!isPasswordValid) {
        errObj.password = TENANT_MESSAGES.PASSWORD_REQUIRED;
      }
      if (isJWPlayerSiteId) {
        errObj.jwPlayerSiteId = TENANT_MESSAGES.JWPLAYER_PROPERTY_ID_REQUIRED;
      }
      if (isJWPlayerSecret) {
        errObj.jwPlayerSecret = TENANT_MESSAGES.JWPLAYER_SECRET_REQUIRED;
      }
    }
    setError({ ...errObj });
    return errObj;
  };
  const getCreateApiPayload = (data) => {
    const activatedProducts = ACTIVATED_PRODUCT_TYPES.filter(
      (product) => data?.[product]
    );
    const matchingLanguageCodes = findLanguageTagsByName(data.LanguageCodes);
    const matchingLanguageTag = findLanguageTagsByName(data.LanguageTag);
    const isProviderActive = data.isProviderActive == "1";

    const body: TenantGeneralSettingsModel = {
      tenantID: data.tenantId,
      name: data.name,
      activatedProducts: activatedProducts,
      customerNumber: data.customerNumber,
      timeZoneInfo: {
        offsetName: data.timezone,
        utcOffset: data.offset,
      },
      isProviderActive: isProviderActive,
      languageCodes: matchingLanguageCodes ?? null,
      languageTag: matchingLanguageTag?.[0] ?? null,
      email: isProviderActive ? data.email : "",
      password: isProviderActive ? data.password : "",
      mediaPlatform: isProviderActive ? 1 : 0,
      jwPlayerSiteId: isProviderActive ? data?.jwPlayerSiteId : null,
      jwPlayerSecret: isProviderActive ? data?.jwPlayerSecret : null,
      audioNormalize: isProviderActive ? data?.audioNormalize : false,
    };
    return body;
  };
  const submitForm = () => {
    const isError = getErrorText();
    if (isEmpty(isError)) {
      setLoading(true);
      let apiCreatePayload = getCreateApiPayload(formData);
      dispatch(
        inEditMode
          ? updateGeneralSettingsTenant(apiCreatePayload)
          : createGeneralSettingsTenant(apiCreatePayload, history)
      );
      setSelectedTenantId(apiCreatePayload.tenantID);
      setIsFormDataChanged(false);
    }
  };
  return {
    formData,
    setFormData,
    inEditMode,
    errors,
    getErrorText,
    setLoading,
    setInEditModel,
    setEmailPasswordEditable,
    isEmailPasswordEditable,
    isLoading,
    submitForm,
    isFormDataLoading,
    appKeyAndSecret,
    modal,
    setModal,
    generateAppKeySecret,
    appKeySecretLoading,
    selectedTenantId,
    apiDataLoading,
    apiData,
    isSameFormData,
    setIsSameFormData,
    showModalBox,
    unsavedModal,
    setIsFormDataChanged,
    handleUnsavedModalOkClick,
    handleUnsavedModalClose,
  };
};
