interface TimeZoneInfo {
  offsetName: string;
  utcOffset: string;
}

interface BaseData {
  name: string;
  tenantId: string;
  customerNumber: string;
  timezone: string;
  offset: string;
  isProviderActive: number | boolean;
  email: string;
  password: string;
  mediaPlatform: number;
  jwPlayerSiteId: string | null;
  jwPlayerSecret: string | null;
  audioNormalize: boolean;
}

export interface TenantGeneralSettingsModel{
  tenantID: string;
  name: string;
  activatedProducts: string[];
  customerNumber: string;
  timeZoneInfo: TimeZoneInfo;
  isProviderActive: boolean;
  languageCodes: string[] | null;
  languageTag: string | null;
  email: string;
  password: string;
  mediaPlatform: number;
  jwPlayerSiteId: string | null;
  jwPlayerSecret: string | null;
  audioNormalize: boolean;
}


export interface FormData extends BaseData {
  providerId: string;
  LanguageCodes: string[]; // replace string[] with the correct type if it's not an array of strings
  LanguageTag: string;
  web: boolean;
  api: boolean;
  virtual: boolean;
  app: boolean;
}
enum SubscriptionSourceType {
  PSEUDOSO = "pseudosso",
  FLATFILE = "flatfile",
  STRIPE = "stripe",
  MMS = "mms",
  APPLE = "apple"
}

export const subscriptionSource: SubscriptionSourceType[] = [
  SubscriptionSourceType.PSEUDOSO,
  SubscriptionSourceType.FLATFILE,
  SubscriptionSourceType.STRIPE,
  SubscriptionSourceType.MMS,
  SubscriptionSourceType.APPLE
];

export interface AppFormData {
  sharedSecret: string;
  iosProducts: string[] | string;
  localyticsiOSId: string;
  localyticsAndroidId: string;
  myZoneEnabled: boolean;
  apple: boolean;
  subscriptionSource: string[];
  siteSourceType?: string;
}

enum SiteSourceTypeEnums {
  FAC = "fac",
  SSO = "sso",
  SUB = "sub"
}
type SiteSourceType = SiteSourceTypeEnums.FAC | SiteSourceTypeEnums.SSO | SiteSourceTypeEnums.SUB;
export interface WebSettingsFormData {
  siteSourceType: string;
  subscriptionSourceType: string | boolean | null | string[];
  stripe: boolean;
  mms: boolean;
  pseudosso: boolean;
  flatfile: boolean;
  lockedContentBehaviour: string;
  isWexerOwnedCategories: boolean;
  isMarketingConsent: boolean;
  projectId: string;
  url: string;
  localyticsWebId: string;
  secretKey: string;
  webHookKey: string;
  planId: string;
  publishableKey: string;
  pricingTableId: string;
  numberOfMySubscriptionItemsOnHomePage: string;
  landingPageGetAccessLink: string;
  showLogo: boolean;
  showFooter: boolean;
  isLanguageSwitcher: boolean;
  ratingEnabled: boolean;
  scheduleEvents: boolean;
  fitRadioEnabled: boolean;
}

export interface TenantGeneralSettingsData {
  name: string;
  tenantID: string;
  providerID: string | null;
  customerNumber: string;
  activatedProducts: string[];
  timeZoneInfo: {
    offsetName: string;
    utcOffset: string;
  };
  languageCodes: string[];
  languageTag: string;
  isProviderActive: boolean;
  email: string;
  jwPlayerSiteId: string | null;
  jwPlayerSecret: string | null;
  audioNormalize: boolean;
  clientId: string | null;
  clientSecret: string | null;
}
export interface TenantGeneralSettingsDataAPI {
  // ... other properties ...
  tntGeneralSettings?: TenantGeneralSettingsData | []; // replace `any` with the actual type of `tntGeneralSettings`
}
interface WebSettingsDataAPI extends WebSettingsFormData{
  subscriptionSource: string[]; // or whatever the type of subscriptionSource is
  // include other properties of webSettingsData here
}

interface TntByIdData {
  id: string; 
  name:string;
  productList:string[]
}
export interface WebFormProps {
  callback: () => void;
  isCSUser: boolean;
  tenantID: string;
  webSettingsData: WebSettingsDataAPI | null;
  tntByIdData: TntByIdData | null;
  warningModal?: boolean;
  handleWarningModalClose?: (value: boolean) => void;
}
export interface AppFormProps extends WebFormProps {
  appSettingsData: AppFormData | null;
}

export enum Fields {
  SITE_SOURCE_TYPE = "siteSourceType",
  SUBSCRIPTION_SOURCE_TYPE = "subscriptionSourceType",
  STRIPE = "stripe",
  MMS = "mms",
  PSEUDOSSO = "pseudosso",
  FLATFILE = "flatfile",
  LOCKED_CONTENT_BEHAVIOUR = "lockedContentBehaviour",
  IS_WEXER_OWNED_CATEGORIES = "isWexerOwnedCategories",
  IS_MARKETING_CONSENT = "isMarketingConsent",
  PROJECT_ID = "projectId",
  URL = "url",
  LOCALYTICS_WEB_ID = "localyticsWebId",
  SECRET_KEY = "secretKey",
  WEB_HOOK_KEY = "webHookKey",
  PLAN_ID = "planId",
  PUBLISHABLE_KEY = "publishableKey",
  PRICING_TABLE_ID = "pricingTableId",
  NUMBER_OF_MY_SUBSCRIPTION_ITEMS_ON_HOME_PAGE = "numberOfMySubscriptionItemsOnHomePage",
  LANDING_PAGE_GET_ACCESS_LINK = "landingPageGetAccessLink",
  SHOW_LOGO = "showLogo",
  SHOW_FOOTER = "showFooter",
  IS_LANGUAGE_SWITCHER = "isLanguageSwitcher",
  RATING_ENABLED = "ratingEnabled",
  SCHEDULE_EVENTS = "scheduleEvents",
  FIT_RADIO_ENABLED = "fitRadioEnabled",
}

export enum AppFields {
  SHARED_SECRET = "sharedSecret",
  IOS_PRODUCTS = "iosProducts",
  LOCALYTICS_IOS_ID = "localyticsiOSId",
  LOCALYTICS_ANDROID_ID = "localyticsAndroidId",
  MY_ZONE_ENABLED = "myZoneEnabled",
  APPLE = "apple",
  SUBSCRIPTION_SOURCE = "subscriptionSource",
}