import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { apiResponseEnums, actionStatus, USER_MESSAGES } from "constants/enums";

import {
  GeneralSiteSettingsData,
  GetApiGeneralSiteSettingsPayload,
} from "models/SiteSettings/GeneralSiteSettingsModel";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";

interface SiteSettingsState {
  generalSiteSettingsData: GeneralSiteSettingsData | null;
  error: number;
  pageLoading: boolean;
}

const initialState: SiteSettingsState = {
  generalSiteSettingsData: null,
  error: 0,
  pageLoading: false,
};
export const SiteSettingsSlice = createSlice({
  name: "siteSettings",
  initialState,
  reducers: {
    setGeneralSiteSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    getGeneralSiteSettings: (
      state,
      action: PayloadAction<GeneralSiteSettingsData | null>
    ) => {
      let response = action.payload;
      state.generalSiteSettingsData = response;
    },
    setGeneralSiteSettingsList: (
      state,
      action: PayloadAction<GeneralSiteSettingsData | null>
    ) => {
      state.generalSiteSettingsData = action.payload;
    },
    setApiError: (state, action: PayloadAction<number>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getGeneralSiteSettings,
  setApiError,
  setGeneralSiteSettingsList,
  setGeneralSiteSettingsLoading,
} = SiteSettingsSlice.actions;

export const updateGeneralSiteSettings = (
  body: GeneralSiteSettingsData
): AppThunk => (dispatch) => {
  dispatch(setGeneralSiteSettingsLoading(true));
  userApi.post(ApiConstants.updateGeneralSiteSettingsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_MESSAGES.SAVED,
        })
      );
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setGeneralSiteSettingsLoading(false));
  });
};
// get site configuration for configurations type = sociallinks

export const getGeneralSiteSettingsListFromApi = (
  body: GetApiGeneralSiteSettingsPayload
): AppThunk => (dispatch) => {
  dispatch(setGeneralSiteSettingsList(null));
  dispatch(setGeneralSiteSettingsLoading(true));
  userApi.post(ApiConstants.getGeneralSiteSettingsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getGeneralSiteSettings(res.data as GeneralSiteSettingsData));
    } else {
      dispatch(setGeneralSiteSettingsList(null));
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setGeneralSiteSettingsLoading(false));
  });
};

export const isLoadingData = (state: RootState) =>
  state.siteSettings.pageLoading;
export const selectGeneralSiteSettings = (state: RootState) =>
  state.siteSettings.generalSiteSettingsData;
export const generalSiteSettingsCreatedOrNot = (state: RootState) =>
  state.siteSettings.error;

export default SiteSettingsSlice.reducer;