import { log } from "console";
import { primaryColor } from "./themeConstants";

const Translate = require("assets/translations/en.json");

export const authEnums = {
  AUTH_STATUS_UNKNOWN: 0, // Initial state unknonw
  AUTH_STATUS_LOGGED_IN: 1, // Used logged in fireabse db
  AUTH_STATUS_LOGGED_OUT: 2, // User logged out, not found in firease db
};

export const userRoleEnums = {
  ADMIN_USER: 0, // ENUM FOR ADMIN ROLE
  PRVIDER_USER: 1,
  TENANT_AS_PROVIDER_USER: 2,
  CS_USER: 3,
};
export const paginationValues = {
  DEFAULT_PAGE_SIZE: 99999,
  DEFAULT_PAGE_SIZE_FOR_CONTENT: 100,
  DAFAULT_PAGE_NUMBER: 1,
  PAGE_SIZES_OPTIONS: [25, 50, 100],
  PAGE_SIZES_OPTIONS_WITH_ALL: [
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "All", value: 99999 },
  ],
};
export const RICH_TEXT_CHAR_LIMIT = {
  MAX: 500,
};
export const SORT_ITEMS = {
  next_occurrence_time: "next occurrence time",
  created_date: "created date",
};
export const NETWORK_STATUS_ENUMS = {
  OFFLINE: -1,
  UNKNOWN: 0,
  ONLINE: 1,
};

export const apiResponseEnums = {
  OKAY: 200, // ENUM FOR ADMIN ROLE
  CREATED: 201,
  DELETED: 204,
  UNAUTHRISED: 401,
  INTERNAL_ERROR: 500,
  CUSTOM_ERROR_AS_CHANNEL_IS_CLUB_ONLY: 601, // CUSTOM ERROR DEFINED ON BACKEND, IF A CHANNEL IS IN CLUB ONLY(NOT APP AND WEB), IN CAN'T BE PUBLISHED
  BAD_REQUEST: 400,
  CONFLICTED: 409,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405, // Used for checking if methid not allowed from serverß
  TIME_OUT: 502,
  NO_CONTENT: 204,
  NOT_IMPLEMENTED: 501,
};

export const actionStatus = {
  INITIAL_STATUS: 0, // Initial/Default state
  SUCCESS_STATUS: 1, // If operation is success
  FAIL_STATUS: 2, // Api/operation is failed
  CALLBACK_STATUS: 3, // Callback after post success/fail
};

export const channelAvailability = {
  IN_CLUB_AVAILIBLITY: 0,
  APP_AND_WEB_AVAILIBLITY: 1,
  IN_HOTEL_AVAILIBLITY: 2,
};

export const asyncActionEnums = {
  ASYNC_ACTION_NOT_START: 0,
  ASYNC_ACTION_STARTED: 1,
  ASYNC_ACTION_SUCCESS: 2,
  ASYNC_ACTION_FAILED: 3,
};

export const table = {
  maxiMumNumberOrRowsTobeDisplayed: 10,
};
export const RESUMABLE_TITLE_STATUS = {
  ERROR: 1,
  COMPLETE: 2,
  INCOMPLETE: 3,
};
export const titleStatusEnums = {
  None: 0,
  EncodingQueued: 1,
  Encoded: 2,
  PublishQueued: 3,
  Published: 4,
  Error: 5,
  Incomplete: 6,
  Unpublished: 7,
  NormalisationQueued: 11,
  Completed: 8, // Added for Event video
  Uploading: 9, //It is not on backend ,
  Uploaded: 10, //It is not on backend ,
};

export const VIDEO_STATUS_ENUM = {
  UPLOADING: 1,
  UPLOADED: 2,
  NORMALIZING: 3,
  NORMALIZED: 4,
  PROCESSING: 5,
  COMPLETED: 6,
  FAILED: 7,
  CANCELED: 8,
};
export const CONTENT_STATUS_ENUM = {
  // NOT IN USED START //
  NONE: 0,
  ENCODING_QUEUED: 1,
  ENCODED: 2,
  PUBLISH_QUEUED: 3,
  ERROR: 5,
  NORMALIZATION_QUEUED: 11,
  // NOT IN USED END //
  PUBLISHED: 4,
  INCOMPLETE: 6,
  UNPUBLISHED: 7,
  COMPLETE: 8,
};
export const CONTENT_STATUS_AUTO_PUBLISH = [
  CONTENT_STATUS_ENUM.INCOMPLETE,
  CONTENT_STATUS_ENUM.UNPUBLISHED,
];
export const CONTENT_STATUS_PUBLISH = [
  CONTENT_STATUS_ENUM.PUBLISHED,
  CONTENT_STATUS_ENUM.COMPLETE,
];
// export const CONTENT_STATUS_UNPUBLISHED = [CONTENT_STATUS_ENUM.UNPUBLISHED]
// export const CONTENT_STATUS_BTN_DISABLE = [CONTENT_STATUS_ENUM.FAILED,CONTENT_STATUS_ENUM.CANCELED ];
export const VIDEO_STATUS_FAILED = [
  VIDEO_STATUS_ENUM.FAILED,
  VIDEO_STATUS_ENUM.CANCELED,
];
export const VIDEO_STATUS_REPLACE = [VIDEO_STATUS_ENUM.COMPLETED];
export const VIDEO_STATUS_REUPLOAD = VIDEO_STATUS_FAILED;
export const VIDEO_STATUS_TRAILER_REMOVE = VIDEO_STATUS_REPLACE;
export const CHECK_LOADING_STATUS_EDIT_SCREEN = [
  VIDEO_STATUS_ENUM.UPLOADING,
  VIDEO_STATUS_ENUM.UPLOADED,
  VIDEO_STATUS_ENUM.NORMALIZING,
  VIDEO_STATUS_ENUM.NORMALIZED,
  VIDEO_STATUS_ENUM.PROCESSING,
];
export const CROSS_BTN_STATUS = [
  ...CHECK_LOADING_STATUS_EDIT_SCREEN,
  VIDEO_STATUS_ENUM.FAILED,
];
export const VIDEO_STATUS_RECHECK = [
  VIDEO_STATUS_ENUM.COMPLETED,
  ...VIDEO_STATUS_FAILED,
];
export const fileStatusEnum = {
  None: 0,
  FileNotFound: 1,
  EncodingError: 2,
  PublishError: 3,
  EncodedFileNotFound: 4,
  Canceled: 5,
};

export const skillLevelConstants = [
  "1 - Beginner",
  "2 - Beginner",
  "3 - Beginner",
  "4 - Beginner",
  "5 - Intermediate",
  "6 - Intermediate",
  "7 - Intermediate",
  "8 - Advanced",
  "9 - Advanced",
  "10 - Advanced",
];

export const intensityConstants = [
  "1 - Very Slight",
  "2 - Slight",
  "3 - Moderate",
  "4 - Somewhat Hard",
  "5 - Hard",
  "6",
  "7 - Very Hard",
  "8",
  "9 - Extremely hard",
  "10 - Maximum effort",
];

export const durationSecondsRange = {
  [Translate?.Collections?.tenmins]: "0,600",
  [Translate?.Collections?.twentymins]: "601,1200",
  [Translate?.Collections?.thirtymins]: "1201,1800",
  [Translate?.Collections?.fourtymins]: "1801,18000",
};

// helper enums, localStorage
export const mediaPlatforms = [
  {
    tag: 0,
    name: "AMS",
  },

  {
    tag: 1,
    name: "JWPlayer",
  },
];
export const sortCreatedOptions = [
  { label: "CREATED DSC", value: "C-DOWN" },
  { label: "CREATED ASC", value: "C-UP" },
];
export const sortCreatedOptionsForUserReport = [
  { label: "CREATED DSC", value: "C-DOWN" },
  { label: "CREATED ASC", value: "C-UP" },
  { label: "ALPHABET DSC", value: "A-DOWN" },
  { label: "ALPHABET ASC", value: "A-UP" },
];
export const sortDisplayOrder = [{ label: "DISPLAY ORDER", value: "D-UP" }];
export const sortAlphaOptions = [
  { label: "ALPHABET DSC", value: "A-DOWN" },
  { label: "ALPHABET ASC", value: "A-UP" },
];
export const sortAlphaOptionsRev = [
  { label: "ALPHABET ASC", value: "A-UP" },
  { label: "ALPHABET DSC", value: "A-DOWN" },
];
export const sortStartOptions = [
  { label: "START TIME DSC", value: "S-DOWN" },
  { label: "START TIME ASC", value: "S-UP" },
];
export const sortOptions = [...sortCreatedOptions, ...sortAlphaOptions];
export const sortScheduleOptions = [...sortCreatedOptions, ...sortStartOptions];

export const sortDocumentOptions = [...sortCreatedOptions, ...sortAlphaOptions];

export const sortCollectionsOptions = [
  ...sortDisplayOrder,
  ...sortCreatedOptions,
  ...sortAlphaOptions,
];

export const BACKGROUND_FETCH_TIMEOUT = 100000; // 5 miniutes
export const BACKGROUND_FETCH_CONTENT_VIDEO_STATUS_TIME = 1000 * 60 * 3; // 3 miniutes

export const localStorageEventVideos = "eventVideoUploadProgess";

export const localStorageChunkStatus = "uploadedChunkStatus"; // stores last upload chunk in localstorage in titles Area

export const localStorageEventChunkStatus = "uploadedEventChunkStatus"; // stores last upload chunk in localstorage in Event Area

export const WEBPLAYER_URL_COLLECTIONS = "/collection/"; // collection copy url

export const MINIMUM_NUMBER_DELETION_CLASS = 1; // collection minimum number of class

export const MAX_FILE_UPLOAD_LIMIT = 5; // content file upload limit

export const MINIMUM_EVENT_TITLE = 25; // event class title minimum length

export const MINIMUM_COLLECTION_TITLE = 50; // event class title minimum length
export const MAX_VIDEO_FILE_NAME_CHAR = 255;
export const EVENT_TYPE = {
  SIMULATED: "Simulated Live",
  LIVE: "Live",
  VOD: "Scheduled Class",
};

export const EVENT_STATUS = {
  SAVED: "Saved",
  INACTIVE: "Inactive",
  ACTIVE: "Active",
};
export const EVENT_STATUS_VALUES = {
  SAVED: 1,
  INACTIVE: 2,
  ACTIVE: 3,
};

export const EVENT_ACTIVE_INACTIVE_COLOR = {
  ACTIVE: "#36dcb6",
  INACTIVE: "#ff3b30",
  SAVED: "rgba(54, 220, 182, 0.35)",
};

export const ORDER_BY = {
  DESC: "desc",
  ASC: "asc",
};

export const SHOW_ACTION = {
  ALL: "all",
  FAVOURITE: "favourite",
};

export const API_ERRORS_MESSAGES = {
  API_400: Translate?.apiErrorsMessages?.api400,
  API_500: Translate?.apiErrorsMessages?.api500,
  API_404: Translate?.apiErrorsMessages?.api404,
  OTHER_ERRORS: Translate?.apiErrorsMessages?.otherErrors,
  EMAIL_EXISTS: Translate?.apiErrorsMessages?.emailConflict,
  NOT_ALLOWED: Translate?.apiErrorsMessages?.notAllowed,
  UNAUTHORIZED: Translate?.apiErrorsMessages?.unauthorized,
  NO_CONTENT: Translate?.apiErrorsMessages?.noContent,
};

export const USER_MESSAGES = {
  SAVED: Translate?.userMessage.save,
  DELETE: Translate?.userMessage.delete,
  COPIED: Translate?.userMessage.copied,
  PUBLISHED: Translate?.userMessages.itemPublished,
  UPDATED: Translate?.userMessages.itemUpdated,
  DOWNLOAD: Translate?.userMessages.itemUpdated.downloadComplete,
  TRAILER_REMOVE: Translate?.userMessages.trailerRemovedMsg,
  FILE_CANCEL: Translate?.userMessages.fileCanceled,
  TITLE_PUBLISHD: Translate?.userMessages.titlePublished,
  ITEM_DELETE: Translate?.userMessages.itemDeleted,
  COLLECTION_REORDERED: Translate?.userMessages.displayOrderUpdated,
  FAILED_DELETE: Translate?.userMessages.failedDelete,
  DELETE_PREFIX: Translate?.userMessages.deletePrefix,
  DELETE_SUFFIX: Translate?.userMessages.deleteSuffix,
  TITLE_EXPORT: Translate?.userMessages.exportTitles,
  BULK_UPLOAD_PROCESS: Translate?.userMessages.processingSuccessMessage,
  BULK_UPLOAD_STOP: Translate?.userMessages.stopSuccessMessage,
};

export const TENANT_CONNECT_VALUES = {
  NONE: "none",
  ONDEMAND: "ondemand",
  LIVE: "live",
  NULL: "null",
  SCHEDULE: "schedule",
};

export const EVENT_DETAILS_TYPE = {
  USER_DETAILS: 1,
  SET_MANUALLY: 2,
};

export const EVENT_TYPE_VALUES = {
  SIMULATED: 1,
  LIVE: 2,
  VOD: 3,
};
export const EVENT_TAB_VALUES = {
  VOD: 0,
  LIVE: 1,
  SIMULATED: 2,
};
export const EVENT_CATEGORY = {
  CYCLE: 1,
  GX: 2,
};

export const ScheduleFormFields = {
  contentId: "contentId",
  description: "description",
  creationDate: "creationDate",
  title: "title",
  tag: "tag",
  event_Id: "event_Id",
  tenantId: "tenantId",
  active: "active",
  allowAutoPublish: "allowAutoPublish",
  type: "type",
  eventKey: "eventKey",
  durationSecond: "durationSecond",
  category: "category",
  eventDetailsType: "eventDetailsType",
  video: "video",
  dateTime: "dateTime",
  cover: "cover",
  poster: "poster",
  imageUrl: "imageUrl",
  className: "className",
  classDescription: "classDescription",
  imageLink: "imageLink",
};
export const manualSelectedChooseClassFields = [
  {
    key: ScheduleFormFields.video,
    value: ScheduleFormFields.video,
  },
];
export const manualFieldsValuesToNull = [
  {
    key: ScheduleFormFields.description,
    value: ScheduleFormFields.classDescription,
  },
  { key: ScheduleFormFields.title, value: ScheduleFormFields.className },
  { key: ScheduleFormFields.cover, value: ScheduleFormFields.imageLink },
];
export const manualFieldsFilledValuesToNull = [
  {
    key: ScheduleFormFields.description,
    value: ScheduleFormFields.description,
  },
  { key: ScheduleFormFields.title, value: ScheduleFormFields.title },
  { key: ScheduleFormFields.cover, value: ScheduleFormFields.cover },
];
export const manualFillChooseClassFields = [
  {
    key: ScheduleFormFields.eventDetailsType,
    value: ScheduleFormFields.eventDetailsType,
  },
  {
    key: ScheduleFormFields.dateTime,
    value: ScheduleFormFields.dateTime,
  },
  {
    key: ScheduleFormFields.category,
    value: ScheduleFormFields.category,
  },
  { key: ScheduleFormFields.active, value: ScheduleFormFields.active },
  {
    key: ScheduleFormFields.durationSecond,
    value: ScheduleFormFields.durationSecond,
  },
  {
    key: ScheduleFormFields.description,
    value: ScheduleFormFields.description,
  },
  { key: ScheduleFormFields.title, value: ScheduleFormFields.title },
  { key: ScheduleFormFields.cover, value: ScheduleFormFields.cover },
  {
    key: ScheduleFormFields.contentId,
    value: ScheduleFormFields.tag,
  },
];

export const userDetailsFillChooseClassFields = [
  {
    key: ScheduleFormFields.eventDetailsType,
    value: ScheduleFormFields.eventDetailsType,
  },
  {
    key: ScheduleFormFields.dateTime,
    value: ScheduleFormFields.dateTime,
  },
  {
    key: ScheduleFormFields.category,
    value: ScheduleFormFields.category,
  },
  { key: ScheduleFormFields.active, value: ScheduleFormFields.active },
  {
    key: ScheduleFormFields.durationSecond,
    value: ScheduleFormFields.durationSecond,
  },
];

export const userDetailsSelectChooseFields = [
  {
    key: ScheduleFormFields.description,
    value: ScheduleFormFields.classDescription,
  },
  { key: ScheduleFormFields.title, value: ScheduleFormFields.className },
  { key: ScheduleFormFields.cover, value: ScheduleFormFields.imageLink },
  {
    key: ScheduleFormFields.contentId,
    value: ScheduleFormFields.tag,
  },
  {
    key: ScheduleFormFields.video,
    value: ScheduleFormFields.video,
  },
];
export const EditScheduleFormFields = [
  {
    key: ScheduleFormFields.contentId,
    value: ScheduleFormFields.contentId,
  },
  {
    key: ScheduleFormFields.description,
    value: ScheduleFormFields.description,
  },
  {
    key: ScheduleFormFields.creationDate,
    value: ScheduleFormFields.creationDate,
  },
  { key: ScheduleFormFields.title, value: ScheduleFormFields.title },
  { key: ScheduleFormFields.tag, value: ScheduleFormFields.tag },
  {
    key: ScheduleFormFields.event_Id,
    value: ScheduleFormFields.event_Id,
  },
  {
    key: ScheduleFormFields.tenantId,
    value: ScheduleFormFields.tenantId,
  },
  { key: ScheduleFormFields.active, value: ScheduleFormFields.active },
  {
    key: ScheduleFormFields.allowAutoPublish,
    value: ScheduleFormFields.allowAutoPublish,
  },
  { key: ScheduleFormFields.type, value: ScheduleFormFields.type },
  {
    key: ScheduleFormFields.eventKey,
    value: ScheduleFormFields.eventKey,
  },
  {
    key: ScheduleFormFields.durationSecond,
    value: ScheduleFormFields.durationSecond,
  },
  {
    key: ScheduleFormFields.category,
    value: ScheduleFormFields.category,
  },
  {
    key: ScheduleFormFields.eventDetailsType,
    value: ScheduleFormFields.eventDetailsType,
  },
  { key: ScheduleFormFields.video, value: ScheduleFormFields.video },
  {
    key: ScheduleFormFields.dateTime,
    value: ScheduleFormFields.dateTime,
  },
  { key: ScheduleFormFields.cover, value: ScheduleFormFields.imageUrl },
  { key: ScheduleFormFields.poster, value: ScheduleFormFields.imageUrl },
];
export const AddScheduleFormFields = [
  {
    key: ScheduleFormFields.contentId,
    value: ScheduleFormFields.contentId,
  },
  {
    key: ScheduleFormFields.description,
    value: ScheduleFormFields.description,
  },
  {
    key: ScheduleFormFields.creationDate,
    value: ScheduleFormFields.creationDate,
  },
  { key: ScheduleFormFields.title, value: ScheduleFormFields.title },
  { key: ScheduleFormFields.tag, value: ScheduleFormFields.tag },
  {
    key: ScheduleFormFields.event_Id,
    value: ScheduleFormFields.event_Id,
  },
  {
    key: ScheduleFormFields.tenantId,
    value: ScheduleFormFields.tenantId,
  },
  { key: ScheduleFormFields.active, value: ScheduleFormFields.active },
  {
    key: ScheduleFormFields.allowAutoPublish,
    value: ScheduleFormFields.allowAutoPublish,
  },
  { key: ScheduleFormFields.type, value: ScheduleFormFields.type },
  {
    key: ScheduleFormFields.eventKey,
    value: ScheduleFormFields.eventKey,
  },
  {
    key: ScheduleFormFields.durationSecond,
    value: ScheduleFormFields.durationSecond,
  },
  {
    key: ScheduleFormFields.category,
    value: ScheduleFormFields.category,
  },
  {
    key: ScheduleFormFields.eventDetailsType,
    value: ScheduleFormFields.eventDetailsType,
  },
  { key: ScheduleFormFields.video, value: ScheduleFormFields.video },
  {
    key: ScheduleFormFields.dateTime,
    value: ScheduleFormFields.dateTime,
  },
  { key: ScheduleFormFields.cover, value: ScheduleFormFields.cover },
  { key: ScheduleFormFields.poster, value: ScheduleFormFields.cover },
];
export const EditDataFormFields = [
  ScheduleFormFields.contentId,
  ScheduleFormFields.description,
  ScheduleFormFields.title,
  ScheduleFormFields.tag,
  ScheduleFormFields.event_Id,
  ScheduleFormFields.tenantId,
  ScheduleFormFields.active,
  ScheduleFormFields.allowAutoPublish,
  ScheduleFormFields.type,
  ScheduleFormFields.eventDetailsType,
  ScheduleFormFields.eventKey,
  ScheduleFormFields.durationSecond,
  ScheduleFormFields.category,
];
export const FAV_UNFAV_ACTION = {
  FAVOURITE: 1,
  UNFAVOURITE: 2,
};

export const WEEK_DAY_ARR = [0, 1, 2, 3, 4, 5, 6];

export const FORMAT_DATE_TIME_STR = "YYYY-MM-DDTHH:mm:ss";

export const FORMAT_DATE_STR = "YYYY-MM-DD";

export const FORMAT_TIME_STR = "HH:mm:ss";

export const MEDIA_TYPE_FILTER_OPTIONS = ["Video", "Audio"];

export const MEDIA_TYPE_FILTER_VALUES = { Video: "1", Audio: "2" };

export const FORMAT_DATE_CUSTOM_DRAG_ERROR = "DD MMM, HH:mma";

export const DRAG_DROP_TOAST_DURATION = 5000;

export const API_ERROR_TOAST_DURATION = 3000;

export const CONFIGURATION_TYPE = {
  SOCIALLINKS: 1,
  APPLINKS: 2,
  CUSTOM_LINKS: 3,
};

export const DEFAULT_APP_LIST = [
  {
    tag: "",
    httpLink: "",
    configurationSubType: "Apple",
    status: false,
    linkText: null,
    linkHoverText: null,
  },
  {
    tag: "",
    httpLink: "",
    configurationSubType: "Android",
    status: false,
    linkText: null,
    linkHoverText: null,
  },
];
export let DEFAULT_SOCIAL_LIST = [
  {
    tag: "",
    httpLink: "",
    configurationSubType: "Facebook",
    status: false,
    linkText: null,
    linkHoverText: null,
  },
  {
    tag: "",
    httpLink: "",
    configurationSubType: "Twitter",
    status: false,
    linkText: null,
    linkHoverText: null,
  },
  {
    tag: "",
    httpLink: "",
    configurationSubType: "Instagram",
    status: false,
    linkText: null,
    linkHoverText: null,
  },
];

export const MEDIA_TYPE = {
  VIDEO: "Video",
  TRAILER: "Trailer",
};
export const STREAMING_PLATFORM_LIVE = {
  WEXER: 1,
  EXTERNAL: 2,
};

export const BACKGROUND_CONTENT_LIST = 1000 * 60 * 0.5; //1000 * 60 * 3; // 1 miniutes

export const VIDEO_TYPE_TEXT = "mp4";

export const MEDIA_TYPE_VALUES = {
  VIDEO: 1,
  AUDIO: 2,
};

export const MEDIA_TYPE_STATUS = {
  CANCEL: "Cancel",
  REMOVE: "Remove",
};

export const MEDIA_NOT_MATCHED_VIDEO_NOT_EXIST = {
  MESSAGE: "Kindly contact to administrator.",
};

export const TENANT_ID_LENGTH = {
  LIMIT: 50,
};

export const USER_MANAGEMENT_STATUS = {
  COMPLETE: "Completed",
  PROCESSING: "Processing",
};

export const DATE_MONTH_NAME_YEAR = "DD MMM YYYY";
export const DATE_MONTH_NAME_YEAR_TIME = "DD MMM YYYY HH:mm";
export const MAX_LINK_TEXT_CHAR = 30;

export const LEVEL_ASPECT_RATIO = {
  VALUES: 0.02,
};

export const API_ERROR_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const API_ERROR_STATUS_CODE_TYPE = {
  200: "SUCCESS",
  201: "SUCCESS",
  204: "SUCCESS",
  401: "ERROR",
  500: "ERROR",
  400: "ERROR",
  409: "ERROR",
  404: "ERROR",
  405: "ERROR",
  502: "ERROR",
};

export const USER_MANAGEMENT_MESSAGES = {
  DOWNLOADING_REPORT: Translate?.UserManagement?.downloadingReport,
  DOWNLOADING_TEMPLATE: Translate?.UserManagement?.downloadingTemplate,
  UPLOADING_FILE: Translate?.UserManagement?.uploadingFile,
  COLLECTION_REORDER: Translate?.UserManagement?.uploadingFile,
};

export const USER_REPORT_MESSAGES = {
  DOWNLOADING_REPORT: Translate?.UserReport?.downloadUserReport,
};

export const TIME_CONSTANTS = {
  T3s: 3000,
  T5s: 5000,
  T30s: 30000,
};

export const MAX_SUBSCRIPTION_TITLE_CHAR = 40;
export const MAX_SUBSCRIPTION_DESC_CHAR = 2000;

export const SUBSCRIPTION_TYPE = {
  ALL_IN_ONE: "All-in-one",
  BUNDLE: "Bundle",
};
export const SUBSCRIPTION_TYPE_VALUES = {
  ALL_IN_ONE: "allinone",
  BUNDLE: "bundle",
};
export const WELCOME_PAGE_HEADINGS = {
  CONTENT_TITLES: 1,
  CHANNELS: 2,
  HELP_CENTRE: 3,
};
export const ASSETS_TYPE = {
  SUBSCRIPTION_PACKAGE: "subs-package",
  TENANT_CATEGORIES: "tenant-categories",
  SOCIAL_ICONS: "social-icons",
};

export const SCREEN = {
  SUBSCRIPTION_EDIT: "edit",
  MANAGE_SCREEN: "manage",
  MANAGE_SCREEN_ADD: "manage-add",
};

export const FORM_INPUT_MAX_CHAR = {
  TITLE: 40,
  DESCRIPTION: 500,
  DOCUMENT_DESCRIPTION: 90,
  TITLES_DESCRIPTION: 10000,
  CAPTION_TITLE: 100,
};

export const COLLECTION_CLASS_COUNT = {
  VALUES: 5,
};
export const STRIPE = {
  VALUES: "stripe",
};
export const MAX_CATEGORY_TITLE_CHAR = 50;
export const MAX_CATEGORY_DESCRIPTION_CHAR = 375;

export const COLLECTION_STATUS = {
  ACTIVE: "Active",
  DISABLED: "Disabled",
};

export const COLLECTION_REORDER = "reorder";
export const DISPLAY_ORDER = "displayorder";

export const PROMOTED_NONPROMOTED_STATUS = {
  INACTIVE: "Non promoted",
  ACTIVE: "Promoted",
};

export const DOWNLOAD_FILE_NAMES = {
  TITLE_EXPORT: "Content_Titles_Export",
};
export const BULK_UPLOAD_STATUS = {
  InProcess: "Processing",
  Complete: "Complete",
  BEInProcessVal: "In Process",
  BECompleteVal: "Completed",
  Cancelled: "Cancelled",
  Canceled: "Canceled",
  BEProcessVal: "InProcess",
  Stop: "Stop",
};

export const CHECK_INPROGRESS_STATUS_BULK_UPLOAD = [
  BULK_UPLOAD_STATUS.InProcess,
  BULK_UPLOAD_STATUS.BEInProcessVal,
  BULK_UPLOAD_STATUS.BEProcessVal,
];

export const CHECK_CANCEL_STATUS_BULK_UPLOAD = [
  BULK_UPLOAD_STATUS.Cancelled,
  BULK_UPLOAD_STATUS.Canceled,
];
export const CHECK_COMPLETE_STATUS_BULK_UPLOAD = [
  BULK_UPLOAD_STATUS.BECompleteVal,
  BULK_UPLOAD_STATUS.Complete,
];

export const THUMBNAIL_TYPE = {
  AUTO: "auto",
  CUSTOM: "custom",
};

export const ACTIVATED_PRODUCT_TYPES_OBJECTS = {
  web: "web",
  app: "app",
  virtual: "virtual",
  api: "api",
};
export const ACTIVATED_PRODUCT_TYPES = ["web", "app", "virtual", "api"];
export const SiteSourceType = { fac: "fac", sso: "sso", sub: "sub" };
export const SUBSCRIPTION_SOURCE = {
  PSUEDO_SSO: "pseudosso",
  FILE_UPLOAD: "flatfile",
  STRIPE: "stripe",
  MMS: "mms",
  APPLE: "apple",
};
export const LOCK_CONTENT_BEHAVIOUR = {
  do_nothing: "do_nothing",
  show_subscription_options: "show_subscription_options",
  show_popup: "show_popup",
};
export const LANGUAGES = [
  { name: "عربى", tag: "ar" },
  { name: "中文", tag: "zh-hk" },
  { name: "Dansk", tag: "da" },
  { name: "Nederlands", tag: "nl" },
  { name: "English (UK)", tag: "en-gb" },
  { name: "Suomi", tag: "fi" },
  { name: "Français", tag: "fr" },
  { name: "Deutsch (DE)", tag: "de" },
  { name: "Ελληνικά", tag: "el" },
  { name: "עִברִית", tag: "he" },
  { name: "हिन्दी", tag: "hi" },
  { name: "Magyar", tag: "hu" },
  { name: "ID", tag: "id" },
  { name: "Italiano", tag: "it" },
  { name: "日本語", tag: "ja" },
  { name: "Norsk", tag: "no" },
  { name: "Polski", tag: "pl" },
  { name: "Español", tag: "es" },
  { name: "ไทย", tag: "th" },
  { name: "Português", tag: "pt" },
  { name: "Svenska", tag: "sv" },
  { name: "Türkçe", tag: "tr" },
  { name: "Cymraeg", tag: "cy" },
  { name: "한국어", tag: "ko" },
  { name: "English (US)", tag: "en-us" },
  { name: "Deutsch (CH)", tag: "de-ch" },
  { name: "Français (CH)", tag: "fr-ch" },
  { name: "Italiano (CH)", tag: "it-ch" },
];

export const findLanguageTagsByName = (languageNames) => {
  if (!Array.isArray(languageNames)) {
    languageNames = [languageNames];
  }

  // Convert all language names to lowercase
  languageNames = languageNames && languageNames.map((name) => name ? name.toLowerCase() : null);

  return LANGUAGES.filter((language) =>
    languageNames.includes(language.name.toLowerCase())
  ).map((language) => language.tag);
};
export const findLanguageNameByTag = (languageNames) => {
  if (!Array.isArray(languageNames)) {
    languageNames = [languageNames];
  }

  // Convert all language names to lowercase
  languageNames =
    languageNames &&
    languageNames.map((name) => (name ? name.toLowerCase() : null));
    
  return LANGUAGES.filter((language) =>
    languageNames.includes(language.tag.toLowerCase())
  ).map((language) => language.name);
};
export const GENERAL_SETTINGS_MESSAGES = {
  appTitleMessage: Translate?.siteSettingsMessage.appTitleMessage,
  contactEmailAddress: Translate?.siteSettingsMessage.contactEmailAddress,
  notDefaultDeployTenant:
    Translate?.siteSettingsMessage.notDefaultDeployTenant,
  emailValidValidation:Translate?.siteSettingsMessage.emailValidValidation
};
export const WEB_SETTINGS_MESSAGES = {
  logoUrl: Translate?.siteSettingsMessage.logoUrl,
  logoUrlValidation: Translate?.siteSettingsMessage.logoUrlValidation,
  faviconUrl: Translate?.siteSettingsMessage.faviconUrl,
  faviconUrlValidation: Translate?.siteSettingsMessage.faviconUrlValidation,
  homeImageUrl: Translate?.siteSettingsMessage.homeImageUrl,
  homeImageUrlValidation: Translate?.siteSettingsMessage.homeImageUrlValidation,
  primaryColor: Translate?.siteSettingsMessage.primaryColor,
  accentColor: Translate?.siteSettingsMessage.accentColor,
  requiredFormFill: Translate?.siteSettingsMessage.requiredFormFill
};
export const MobileApp_SETTINGS_MESSAGES = {
  logoUrl: Translate?.siteSettingsMessage.logoUrl,
  logoUrlValidation: Translate?.siteSettingsMessage.logoUrlValidation,
  faviconUrl: Translate?.siteSettingsMessage.faviconUrl,
  faviconUrlValidation: Translate?.siteSettingsMessage.faviconUrlValidation,
  homeImageUrl: Translate?.siteSettingsMessage.homeImageUrl,
  homeImageUrlValidation: Translate?.siteSettingsMessage.homeImageUrlValidation,
  primaryColor: Translate?.siteSettingsMessage.primaryColor,
  accentColor: Translate?.siteSettingsMessage.accentColor,
  requiredFormFill: Translate?.siteSettingsMessage.requiredFormFill
};
export const MAX_SITE_TITLE_CHAR = 30;

export const HELP_CENTER_URL ="https://helpcenter.wexer.com/hc/en-us/articles/25268235937815-DRAFT-Mobile-Self-Service-platform-set-up";

export const THEME ={LIGHT:"light",DARK:"dark"}

export const PRODUCT_LIST = {APP:"app",WEB:"web"}

export const TENANT_MESSAGES = {
  NAME_REQUIRED: Translate?.tenantMessages.nameRequired,
  CUSTOMER_NUMBER_REQUIRED: Translate?.tenantMessages.customerNumberRequired,
  TENANT_ID_REQUIRED: Translate?.tenantMessages.tenantIdRequired,
  ACTIVATE_PRODUCTS_REQUIRED:
    Translate?.tenantMessages.activatedProductsRequired,
  TIMEZONE_REQUIRED: Translate?.tenantMessages.timezoneRequired,
  SUPPORTED_LANGUAGES_REQUIRED:
    Translate?.tenantMessages.supportedLanguagesRequired,
  DEFAULT_LANGUAGE_REQUIRED: Translate?.tenantMessages.defaultLanguageRequired,
  EMAIL_REQUIRED: Translate?.tenantMessages.emailRequired,
  PASSWORD_REQUIRED: Translate?.tenantMessages.passwordRequired,
  JWPLAYER_PROPERTY_ID_REQUIRED:
    Translate?.tenantMessages.jwPlayerPropertyIdRequired,
  JWPLAYER_SECRET_REQUIRED: Translate?.tenantMessages.jwPlayerSecretRequired,
  AUDIO_NORMALIZE_REQUIRED: Translate?.tenantMessages.audioNormalizeRequired,
  SITE_TYPE_REQUIRED: Translate?.tenantMessages.siteTypeRequired,
  LOCK_CONTENT_BEHAVIOUR_REQUIRED:
    Translate?.tenantMessages.lockedContentBehaviourRequired,
  PO_EDIOTR_PROJECT_ID_REQUIRED:
    Translate?.tenantMessages.POEditorProjectIDRequired,
  WEB_PLAYER_URL_REQUIRED: Translate?.tenantMessages.webPlayerURLRequired,
  LOCALYTICS_WEB_ID_REQUIRED:
    Translate?.tenantMessages.localyticsWebAppIDRequired,
  LANDING_PAGE_GET_ACCESS_LINK_REQUIRED:
    Translate?.tenantMessages.landingPageAccessLinkRequired,
  HOMEPAGE_SUBSCRIPTION_COUNT_REQUIRED:
    Translate?.tenantMessages.homepageSubscriptionCountRequired,
  SUBSCRIPTION_SOURCE_REQUIRED:
    Translate?.tenantMessages.subscriptionSourceRequired,
  STRIPE_MANDATORY: Translate?.tenantMessages.stripeMandatoryFields,
  SUBSCRIPTION_SOURCE_ONE_REQUIRED:
    Translate?.tenantMessages.subMandatoryFields,
  STRIPE_SECRET_KEY_REQUIRED: Translate?.tenantMessages.stripeSecretKeyRequired,
  STRIPE_PUBLISHABLE_KEY_REQUIRED:
    Translate?.tenantMessages.stripePublishableKeyRequired,
  STRIPE_WEBHOOK_SECRET_REQUIRED:
    Translate?.tenantMessages.stripeWebhookSecretKeyRequired,
  STRIPE_PRICE_ID_REQUIRED:
    Translate?.tenantMessages.stripePricingTableIDRequired,
  STRIPE_PLAN_ID_REQUIRED: Translate?.tenantMessages.stripePlanIDRequired,
  APPLE_REQUIRED: Translate?.tenantMessages.appleMandatoryFields,
  SHARED_SECRET_REQUIRED: Translate?.tenantMessages.sharedSecretRequired,
  IOS_PRODUCTS_REQUIRED: Translate?.tenantMessages.iosProductsRequired,
  LOCALYTICS_IOS_ID_REQUIRED:
    Translate?.tenantMessages.localyticsiOSAppIDRequired,
  LOCALYTICS_ANDROID_ID_REQUIRED:
    Translate?.tenantMessages.localyticsAndroidAppIDRequired,
    VALID_URL: Translate?.tenantMessages.validUrl,
};
export const NO_SUBSCRIPTION_COUNT_HOME_PAGE = "2";

