import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi, api } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { apiResponseEnums, actionStatus, USER_MESSAGES } from "constants/enums";
import {
  WebSiteSettingsData,
  GetApiWebSiteSettingsPayload,
  WebSiteSettingsPayload,
} from "models/SiteSettings/WebSiteSettingsModel";
import axios from 'axios'; // If you prefer axios
 
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";

interface WebSiteSettingsState {
  error: number;
  pageLoading: boolean;
  webSiteSettingsData: WebSiteSettingsPayload | null;
  isURLAccessible: boolean;
}

const initialState: WebSiteSettingsState = {
  error: 0,
  pageLoading: false,
  webSiteSettingsData: null,
  isURLAccessible:false,
};
export const WebSiteSettingsSlice = createSlice({
  name: "webSettings",
  initialState,
  reducers: {
    setWebSiteSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    setWebSiteSettingsList: (
      state,
      action: PayloadAction<WebSiteSettingsPayload | null>
    ) => {
      state.webSiteSettingsData = action.payload;
    },
    setApiError: (state, action: PayloadAction<number>) => {
      state.error = action.payload;
    },
    setIsURLAccessible: (state, action: PayloadAction<boolean>) => {
      state.isURLAccessible = action.payload;
    },
  },
});

export const {
  setApiError,
  setWebSiteSettingsLoading,
  setWebSiteSettingsList,
  setIsURLAccessible
} = WebSiteSettingsSlice.actions;

export const updateWebSiteSettings = (
  body: WebSiteSettingsPayload
): AppThunk => (dispatch) => {
  dispatch(setWebSiteSettingsLoading(true));
  userApi.post(ApiConstants.updateWebSiteSettingsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_MESSAGES.SAVED,
        })
      );
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setWebSiteSettingsLoading(false));
  });
};


export const checkVisitSiteUrl = (url: string): AppThunk => (dispatch) => {
  dispatch(setIsURLAccessible(false));
  userApi
    .post(ApiConstants.urlAccessibleAPI, { url: url })
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setIsURLAccessible(true));
      } else {
        dispatch(setIsURLAccessible(false));
      }
    })
    .catch((error) => {
      dispatch(setIsURLAccessible(false));
    });
  };
// get site configuration for configurations type = sociallinks

export const getWebSiteSettingsListFromApi = (
  body: GetApiWebSiteSettingsPayload
): AppThunk => (dispatch) => {
  dispatch(setWebSiteSettingsList(null));
  dispatch(setWebSiteSettingsLoading(true));
  userApi.post(ApiConstants.getWebSiteSettingsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      let response = res.data as WebSiteSettingsPayload;
      dispatch(setWebSiteSettingsList(response));
    } else {
      dispatch(setWebSiteSettingsList(null));
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setWebSiteSettingsLoading(false));
  });
};

export const isLoadingData = (state: RootState) =>
  state.webSettings.pageLoading;
export const generalSiteSettingsCreatedOrNot = (state: RootState) =>
  state.webSettings.error;
export const selectWebSiteSettings = (state: RootState) =>
  state.webSettings.webSiteSettingsData;

export const selectIsURLAccessible = (state: RootState) =>
         state.webSettings.isURLAccessible;

export default WebSiteSettingsSlice.reducer;
