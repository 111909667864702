import React from "react";
import Providers from "@material-ui/icons/PeopleRounded";
import Key from "@material-ui/icons/VpnKeyRounded";
import Setting from "@material-ui/icons/SettingsRounded";
import Content from "@material-ui/icons/VideoLibraryRounded";
import Channels from "@material-ui/icons/SettingsRemoteRounded";
import ScheduleIcon from "@material-ui/icons/CalendarToday";
import SettingsIcon from "@material-ui/icons/Settings";
import { userRoleEnums, TENANT_CONNECT_VALUES } from "constants/enums";
import CollectionIcon from "@material-ui/icons/ViewModule";
import PictureAsPdfIcon from "@material-ui/icons//PictureAsPdf";
import * as Routes from "constants/routeConstants";
import { selectTenants } from "_pages/channels/channelSlice";
import { useSelector } from "react-redux";
import {
  selectTenantAsProviders,
  selectProviders,
} from "_pages/provider/providerSlice";
import SubscriptionIcon from "@material-ui/icons/Subscriptions";
import {SupervisedUserCircle} from "@material-ui/icons";
import {PRODUCT_LIST } from "constants/enums";
export type MenuItem = {
  id: string;
  icon: JSX.Element | boolean,
  routesTo: string | boolean,
  children?: MenuItem[],
}

let tenantAsProvider: MenuItem[] = [
  {
    id: "schedule",
    icon: <ScheduleIcon />,
    routesTo: Routes.scheduleTitles,
  },
  {
    id: "collections",
    icon: <CollectionIcon />,
    routesTo: Routes.collectionsPath,
  },
  {
    id: "siteManagement",
    icon: false,
    routesTo: false,
  },
  {
    id: "siteSettings",
    icon: <SettingsIcon />,
    routesTo: Routes.siteSettings,
    children: [
      {
        id: "generalSettings",
        icon: false,
        routesTo: Routes.generalSiteSettingsPath,
      },
      {
        id: "webSettings",
        icon: false,
        routesTo: Routes.webSiteSettingsPath,
      },
      {
        id: "appSettings",
        icon: false,
        routesTo: Routes.appSiteSettingsPath,
      },
      {
        id: "socialLinks",
        icon: false,
        routesTo: Routes.socialLinks,
      },
      {
        id: "customLinks",
        icon: false,
        routesTo: Routes.customLinks,
      },
      {
        id: "appLinks",
        icon: false,
        routesTo: Routes.appLinks,
      },
    ],
  },
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "categories",
        icon: false,
        routesTo: Routes.categoriesPath,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      {
        id: "bulkUpload",
        icon: false,
        routesTo: Routes.bulkUploadPath,
      },
    ],
  },
  {
    id: "documents",
    icon: <PictureAsPdfIcon />,
    routesTo: Routes.documentsPath,
  },
  {
    id: "subscriptions",
    icon: <SubscriptionIcon />,
    routesTo: Routes.subscriptionsPath,
  },
  {
    id: "users",
    icon: <SupervisedUserCircle />,
    routesTo: Routes.user,
    children: [
      {
        id: "userManagement",
        icon: false,
        routesTo: Routes.userManagement,
      },
      {
        id: "userReport",
        icon: false,
        routesTo: Routes.userReportPath,
      },
    ],
  },
];
const adminCategories: MenuItem[] = [
  { id: "admin", icon: <Setting />, routesTo: Routes.adminPath },
  { id: "tenants", icon: <Key />, routesTo: Routes.tenantPath },
  {
    id: "providers",
    icon: <Providers />,
    routesTo: Routes.providerPath,
  },
  {
    id: "channels",
    icon: <Channels />,
    routesTo: Routes.channelsPath,
  },
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      
    ],
  },
];

const providerCategories: MenuItem[] = [
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      {
        id: "bulkUpload",
        icon: false,
        routesTo: Routes.bulkUploadPath,
      },
    ],
  },
];

const csCategories: MenuItem[] = [
  { id: "admin", icon: <Setting />, routesTo: Routes.adminPath },
  { id: "tenants", icon: <Key />, routesTo: Routes.tenantPath },
  {
    id: "providers",
    icon: <Providers />,
    routesTo: Routes.providerPath,
  },
  {
    id: "channels",
    icon: <Channels />,
    routesTo: Routes.channelsPath,
  },
  {
    id: "siteManagement",
    icon: false,
    routesTo: false,
  },
  {
    id: "siteSettings",
    icon: <SettingsIcon />,
    routesTo: Routes.siteSettings,
    children: [
      {
        id: "generalSettings",
        icon: false,
        routesTo: Routes.generalSiteSettingsPath,
      },
      {
        id: "webSettings",
        icon: false,
        routesTo: Routes.webSiteSettingsPath,
      },
      {
        id: "appSettings",
        icon: false,
        routesTo: Routes.appSiteSettingsPath,
      },
      {
        id: "socialLinks",
        icon: false,
        routesTo: Routes.socialLinks,
      },
      {
        id: "customLinks",
        icon: false,
        routesTo: Routes.customLinks,
      },
      {
        id: "appLinks",
        icon: false,
        routesTo: Routes.appLinks,
      },
    ],
  },
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "categories",
        icon: false,
        routesTo: Routes.categoriesPath,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      {
        id: "bulkUpload",
        icon: false,
        routesTo: Routes.bulkUploadPath,
      },
    ],
  },
  {
    id: "schedule",
    icon: <ScheduleIcon />,
    routesTo: Routes.scheduleTitles,
  },
  {
    id: "collections",
    icon: <CollectionIcon />,
    routesTo: Routes.collectionsPath,
  },
  {
    id: "subscriptions",
    icon: <SubscriptionIcon />,
    routesTo: Routes.subscriptionsPath,
  },

  {
    id: "documents",
    icon: <PictureAsPdfIcon />,
    routesTo: Routes.documentsPath,
  },
  {
    id: "users",
    icon: <SupervisedUserCircle />,
    routesTo: Routes.user,
    children: [
      {
        id: "userManagement",
        icon: false,
        routesTo: Routes.userManagement,
      },
      {
        id: "userReport",
        icon: false,
        routesTo: Routes.userReportPath,
      },
    ],
  },
];

const MEDIA = "media";
const SCHEDULE = "schedule";
const WEB_SETTINGS = "webSettings";
const APP_SETTINGS = "appSettings";
const BULK_UPLOAD = "bulkUpload";

const removeSettingsBasedOnProduct = (categories, hasWeb, hasApp,hasBulkUpload) => {
  let updatedCategories = categories;

  if (!hasWeb) {
    updatedCategories = removeChildById(updatedCategories, WEB_SETTINGS);
  }

  if (!hasApp) {
    updatedCategories = removeChildById(updatedCategories, APP_SETTINGS);
  }
   if (!hasBulkUpload) {
     updatedCategories = removeChildById(updatedCategories, BULK_UPLOAD);
   }

  return updatedCategories;
};

const removeChildById = (categories, id) => {
  return categories.map((category) => {
    if (category.children) {
      category.children = category.children.filter((child) => child.id !== id);
    }
    return category;
  });
};

export const useCategories = (role) => {
  let tenant = useSelector(selectTenants);
  let providerAsTenant = useSelector(selectTenantAsProviders);
  let providerData = useSelector(selectProviders);
  let tenantId = providerAsTenant?.tenantAsProviders[0]?.tenantId;
  var tenantChannel = tenant?.tenants?.find((tenant) => tenant.id === tenantId);
  var hasBulkUpload =
    providerAsTenant?.tenantAsProviders[0]?.isBulkUploadEnabled;
  var hasBulkUploadProvider = providerData?.providers[0]?.isBulkUploadEnabled;

  var productList = tenant?.tenants[0]?.productList;

  const productExists = (productList, product) => {
    return productList
      ?.map((item) => item.toLowerCase())
      .includes(product.toLowerCase());
  };
  
  const hasApp = productExists(productList, PRODUCT_LIST.APP);
  const hasWeb = productExists(productList, PRODUCT_LIST.WEB);

  switch (role) {
    case userRoleEnums.ADMIN_USER:
      return adminCategories;
    case userRoleEnums.TENANT_AS_PROVIDER_USER:
      if (
        tenantChannel?.connect === null ||
        tenantChannel?.connect === TENANT_CONNECT_VALUES.ONDEMAND ||
        tenantChannel?.connect === TENANT_CONNECT_VALUES.NULL ||
        tenantChannel?.connect === TENANT_CONNECT_VALUES.NONE
      ) {
        tenantAsProvider = tenantAsProvider.filter(
          (item) => item.id !== SCHEDULE
        );
      }
      return removeSettingsBasedOnProduct(
        tenantAsProvider,
        hasWeb,
        hasApp,
        hasBulkUpload
      );
    case userRoleEnums.CS_USER:
      return csCategories;
    default:
      return removeSettingsBasedOnProduct(
        providerCategories,
        hasWeb,
        hasApp,
        hasBulkUploadProvider
      );
  }
};
