import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
import {
  tenantGeneralSettingsData,
  setAppKeyAndSecret,
  getTenantsGeneralSetApi,
  tenantWebSettingsData,
  getTenantWebSetApi,
  getTenantAppSetApi,
  tenantAppSettingsData,
  getTenantByIdData,
  tenantByIdData,
  getAppKeySecretFromAPI,
} from "_pages/tenants/tenantSlice";
import * as RouteContants from "constants/routeConstants";
export const useTenantGeneralSettingsEditHook = () => {
  const { tntGeneralSettings } = useSelector(tenantGeneralSettingsData);
  const { tntWebSettingsData } = useSelector(tenantWebSettingsData);
  const { tntAppSettingsData } = useSelector(tenantAppSettingsData);
  const { tntByIdData } = useSelector(tenantByIdData);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  
  useEffect(() => {
    if (id !== "null" || id !== null) {
      if (location.pathname.includes(RouteContants.editGeneralSettingsPath)) {
        dispatch(getTenantByIdData(id));
        dispatch(getTenantsGeneralSetApi(id));
        dispatch(getAppKeySecretFromAPI(id));
      }
      if (location.pathname.includes(RouteContants.editWebSettingsPath)) {
        dispatch(getTenantByIdData(id));
        dispatch(getTenantWebSetApi(id));
      }
      if (location.pathname.includes(RouteContants.editMobileSettingsPath)) {
         dispatch(getTenantByIdData(id));
         dispatch(getTenantAppSetApi(id));
      }
    }
    return () => {
      dispatch(setAppKeyAndSecret({}));
    };
  }, []);

  
  return {
    tntGeneralSettings,
    id,
    tntWebSettingsData,
    tntAppSettingsData,
    tntByIdData,
  };
};
