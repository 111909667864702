import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import {
  apiResponseEnums, USER_MESSAGES,
} from "constants/enums";
import {
  CategoryModel,
  GetApiCategoryPayload,
  TenantConfigLanguageModel,
} from "models/Category/CategoryModel";
import { ApiResponse } from "apisauce";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";
import { History } from "history";
import { categoriesPath} from "constants/routeConstants";
import { getmetaDataFromApi } from "_pages/contentTitles/contentTitleSlice";

interface CategoryLinksState {
  tenantConfigData: TenantConfigLanguageModel;
  configLoading: boolean;
  submitLoading: boolean;
  dataLoading: boolean;
  categoryData: CategoryModel | null;
}

const initialState: CategoryLinksState = {
  tenantConfigData: {
    supportedLanguages: [],
    defaultLanguage: "",
  },
  configLoading: true,
  submitLoading: false,
  dataLoading: true,
  categoryData: null,
};

export const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setConfigLoading: (state, action: PayloadAction<boolean>) => {
      state.configLoading = action.payload;
    },
    setTenantConfig: (
      state,
      action: PayloadAction<TenantConfigLanguageModel>
    ) => {
      state.tenantConfigData = action.payload;
    },
    resetTenantConfig: (state) => {
      state.tenantConfigData = {
        supportedLanguages: [],
        defaultLanguage: "",
      };
      state.configLoading = true;
    },
    setSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.submitLoading = action.payload;
    },
    setDataLoading: (state, action: PayloadAction<boolean>) => {
      state.dataLoading = action.payload;
    },
    setCategoryData: (
      state,
      action: PayloadAction<any|null>
    ) => {
      state.categoryData = action.payload;
    },
  },
});

export const {
  setConfigLoading,
  setTenantConfig,
  resetTenantConfig,
  setSubmitLoading,
  setDataLoading,
  setCategoryData,
} = CategorySlice.actions;

export const getTenantConfigData = (tenantId: string): AppThunk => (
  dispatch
) => {
  dispatch(setConfigLoading(true));
  userApi
    .get(`${ApiConstants.getTenantConfigAPI}/${tenantId}`)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        const {
          languageCodes: supportedLanguages,
          languageTag: defaultLanguage,
        } = res.data[0].tenant;
        dispatch(setTenantConfig({ supportedLanguages, defaultLanguage }));
        dispatch(setConfigLoading(false));
      } else {
        dispatch(setConfigLoading(false));
      }
    });
};

export const addCategory = (
  data,
  history: History
): AppThunk => (dispatch, getState) => {
  dispatch(setSubmitLoading(true));
  userApi
    .post(`${ApiConstants.addCategoryAPI}`,data)
    .then((res: ApiResponse<any>) => {
       if (res.status == apiResponseEnums.OKAY) {
           dispatch(
             setApiResponse({
               status: res.status,
               data: USER_MESSAGES.SAVED,
             })
           );
         dispatch(setSubmitLoading(false));
          dispatch(getmetaDataFromApi());
        history.push(`${categoriesPath}/${res.data["categoryId"]}/edit`);
       }
       else
       {
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage({
                status: res.status as number,
                data: res.data as ErrorObjectType,
              }),
            })
          );
       }
    });

};


export const updateCategory = (
  data,
  history: History
): AppThunk => (dispatch, getState) => {
  dispatch(setSubmitLoading(true));
  userApi
    .post(`${ApiConstants.updateCategoryAPI}`, data)
    .then((res: ApiResponse<any>) => {
      dispatch(setSubmitLoading(false));
      if (res.status == apiResponseEnums.OKAY) {
            dispatch(
              setApiResponse({
                status: res.status,
                data: USER_MESSAGES.SAVED,
              })
            );
             dispatch(getmetaDataFromApi());
          if (history.location.pathname.includes("subcategory"))
          {
            history.push(`${categoriesPath}/${res.data["categoryId"]}/edit`);
          }
          else
          {
           history.push(`${categoriesPath}/${res.data["categoryId"]}/edit`);
          }
            
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
  };

  export const reorderCategory = (data, history: History): AppThunk => (
    dispatch,
    getState
  ) => {
    dispatch(setSubmitLoading(true));
    userApi
      .post(`${ApiConstants.reorderCategoryAPI}`, data)
      .then((res: ApiResponse<any>) => {
        if (res.status == apiResponseEnums.OKAY) {
            history.push(`${categoriesPath}`);
          dispatch(setSubmitLoading(false));
        } else {
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage({
                status: res.status as number,
                data: res.data as ErrorObjectType,
              }),
            })
          );
        }
      });
  };


export const getCategoryData = (body: GetApiCategoryPayload): AppThunk => (
  dispatch
) => {
  dispatch(setDataLoading(true));
  dispatch(setCategoryData(null));
  userApi
    .post(ApiConstants.getCategoryAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setCategoryData(res.data as CategoryModel));
        dispatch(setDataLoading(false));
      } else {
        dispatch(
          setCategoryData({
            data: [],
          } as CategoryModel)
        );
        dispatch(setDataLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const tenantConfigLoading = (state: RootState) =>
  state.category.configLoading;

export const languageConfig = (state: RootState) =>
  state?.category?.tenantConfigData;

export const submitLoading = (state: RootState) =>
  state.category.submitLoading;

export const categoryDataLoading = (state: RootState) => state.category.dataLoading;

export const categoryData = (state: RootState) => state.category.categoryData;

export default CategorySlice.reducer;
