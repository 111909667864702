import React, { useState } from "react";
import Title from "../Titles/Title";
import TitleStatusButton, {
  BulkUploadStatusButton,
} from "../Tables/TitleStatus";
import { PaperHeaderComponentStyles } from "style/components";
import { ReactComponent as OpenNew } from "../../assets/images/open-new.svg";
import { Typography } from "@material-ui/core";
import Translate from "utils/translate";
import { HELP_CENTER_URL } from "constants/enums";
import Images from "components/SVGImages";
import Tooltip from "@material-ui/core/Tooltip";
const Breadcrumb = (props) => {
  const classes = PaperHeaderComponentStyles();
  const { top = "top-10" } = props;
  return (
    <React.Fragment>
      <div className={classes.header + " sticky-position-top " + top}>
        <Title {...props} />
      </div>
    </React.Fragment>
  );
};

export const BreadcrumbWithLable = (props) => {
  const classes = PaperHeaderComponentStyles();
  const { status, mediaType } = props;

  return (
    <React.Fragment>
      <div className={classes.headerLable + " sticky-position-top top-10"}>
        <Title {...props} />
        <TitleStatusButton isBreadcrumb status={status} mediaType={mediaType} />
      </div>
    </React.Fragment>
  );
};

export const BreadcrumbStatusBulkUpload = (props) => {
  const classes = PaperHeaderComponentStyles();
  const { status } = props;

  return (
    <React.Fragment>
      <div className={classes.headerLable + " sticky-position-top top-10"}>
        <Title {...props} />
        <BulkUploadStatusButton isBreadcrumb status={status} />
      </div>
    </React.Fragment>
  );
};

export const BreadcrumbSiteSettings = ({
  ...props
}) => {
  const classes = PaperHeaderComponentStyles();

  const openHelpCenter = () => window.open(HELP_CENTER_URL, "_blank");

  const tooltipMsg: any = Translate({
    id: "siteSettingsMessage.notDefaultDeployTenant",
  });

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredVisit, setIsHoveredVisit] = useState(false);

  const handleMouseHover = (setHoverState) => () => {
    setHoverState((prevState) => !prevState);
  };

  const renderImage = (
    isHovered,
    src,
    srcHover,
    alt,
    onClick,
    onMouseEnter,
    onMouseLeave
  ) => (
    <img
      src={isHovered ? srcHover : src}
      className={classes.iconText}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      alt={alt}
    />
  );

  return (
    <div
      className={`${classes.siteSettingTopHeader} sticky-position-top top-10 settings-action`}
    >
      <Title {...props} />
      {props?.tenantId && (
        <>
          <Typography
            className={classes.settingsButtonText}
            onClick={openHelpCenter}
          >
            {Translate({ id: "buttons.helpCenter" })}
          </Typography>
          <OpenNew
            onClick={openHelpCenter}
            className={classes.iconActionNewOpen}
          />
          <Typography className={classes.settingsButtonText}></Typography>
          {renderImage(
            isHovered,
            Images.HelpCenter,
            Images.HelpCenterRollOver,
            Images.HelpCenter,
            openHelpCenter,
            handleMouseHover(setIsHovered),
            handleMouseHover(setIsHovered)
          )}
          {props?.isDisableVisitSite && (
            <>
              <div className={classes.visitTextBefore}></div>
              <Typography
                className={
                  !props?.isToolTip
                    ? classes.visitText
                    : classes.visitTextDisable
                }
                onClick={props?.visitSiteCallback}
              >
                {Translate({ id: "buttons.visitSite" })}
              </Typography>
              {!props?.isToolTip ? (
                <div>
                  {renderImage(
                    isHoveredVisit,
                    Images.VisitSite,
                    Images.VisitSiteRollOver,
                    Images.HelpCenter,
                    props?.visitSiteCallback,
                    handleMouseHover(setIsHoveredVisit),
                    handleMouseHover(setIsHoveredVisit)
                  )}
                </div>
              ) : (
                <Tooltip title={tooltipMsg}>
                  <div className={classes.visitTextDisable}>
                    {renderImage(
                      isHoveredVisit,
                      Images.VisitSiteDisabled,
                      Images.VisitSiteDisabled,
                      Images.HelpCenter,
                      props?.visitSiteCallback,
                      handleMouseHover(setIsHoveredVisit),
                      handleMouseHover(setIsHoveredVisit)
                    )}
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Breadcrumb;
