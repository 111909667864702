import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import {
  updateAppSettingsTenant,
  getLoadingAPIData,
} from "_pages/tenants/tenantSlice";
import { isEmpty } from "lodash";
import { useParams } from "react-router";
import {
  SUBSCRIPTION_SOURCE,
  SiteSourceType,
  TENANT_MESSAGES,
  ACTIVATED_PRODUCT_TYPES_OBJECTS,
} from "constants/enums";
import {
  AppFormData,
  AppFormProps,
  AppFields,
} from "models/Tenants/TenantsGeneralSettingsModel";
import { isEqual } from "lodash";
import { useHistory } from "react-router";

export const useTenantMobileAppSettingsForm = (props: AppFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const initialFormData: AppFormData = {
    [AppFields.SHARED_SECRET]: "",
    [AppFields.IOS_PRODUCTS]: [],
    [AppFields.LOCALYTICS_IOS_ID]: "",
    [AppFields.LOCALYTICS_ANDROID_ID]: "",
    [AppFields.MY_ZONE_ENABLED]: false,
    [AppFields.APPLE]: false,
    [AppFields.SUBSCRIPTION_SOURCE]: [],
  };

  const [formData, setFormData] = useState<AppFormData>(initialFormData);
  const [errors, setError] = useState<AppFormData>(initialFormData);
  const apiDataLoading = useSelector<boolean>(getLoadingAPIData);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);

  const [isSameFormData, setIsSameFormData] = useState(false);
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [tabRoutingPath, setTabRoutingPath] = useState<string>("");
  const [apiData, setApiData] = useState<AppFormData>(initialFormData);

  useEffect(() => {
    return () => setFormData(initialFormData);
  }, []);

  useEffect(() => {
    ScrollTop();
  }, []);

  const checkConditions = () => {
    const { webSettingsData } = props;
    const siteSourceType = webSettingsData?.siteSourceType?.toLowerCase();
    const subscriptionSource = webSettingsData?.subscriptionSource;

    return (
      siteSourceType === SiteSourceType.sub.toLowerCase() &&
      subscriptionSource
        ?.map((source) => source.toLowerCase())
        .includes(SUBSCRIPTION_SOURCE.STRIPE.toLowerCase())
    );
  };

  const getErrorText = (stateData: any = false) => {
    let frmData: any = stateData ? stateData : formData;
    let errObj: any = {};
    const requiredFields = [
      {
        field: AppFields.LOCALYTICS_IOS_ID,
        message: TENANT_MESSAGES.LOCALYTICS_IOS_ID_REQUIRED,
      },
      {
        field: AppFields.LOCALYTICS_ANDROID_ID,
        message: TENANT_MESSAGES.LOCALYTICS_ANDROID_ID_REQUIRED,
      },
    ];
   
    const checkFields = (fields: any[]) => {
      fields.forEach(({ field, message }) => {
        if (!frmData[field] || frmData[field].length === 0) {
          errObj[field] = message;
        }
      });
    };
    checkFields(requiredFields);
    if (checkConditions()) {
      checkFields([
        { field: AppFields.APPLE, message: TENANT_MESSAGES.APPLE_REQUIRED },
        {
          field: AppFields.SHARED_SECRET,
          message: TENANT_MESSAGES.SHARED_SECRET_REQUIRED,
        },
        {
          field: AppFields.IOS_PRODUCTS,
          message: TENANT_MESSAGES.IOS_PRODUCTS_REQUIRED,
        },
      ]);
    }
    setError(errObj);
    return errObj;
  };
  const processIosProducts = (formData) => {
    if (formData?.iosProducts?.length > 0) {
      return formData.iosProducts
        .split(",")
        .map((entry) => entry.trim())
        .filter((entry) => entry.trim() !== "");
    }
    return [];
  };
  const getCreateApiPayload = (formData) => {
    const apiPayload = {
      ...formData,
      subscriptionSource: formData?.apple ? [SUBSCRIPTION_SOURCE.APPLE] : [],
      iosProducts: processIosProducts(formData),
      tenantID: id,
    };
    return apiPayload;
  };

  useEffect(() => {
    if (apiDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [props.appSettingsData, apiDataLoading]);

  const checkAppleSubscription = (selectedTenantAppSet) => {
    return (
      (Array.isArray(selectedTenantAppSet?.subscriptionSource) &&
        selectedTenantAppSet?.subscriptionSource.includes(
          SUBSCRIPTION_SOURCE.APPLE
        )) ||
      false
    );
  };
  const processIosProductsToString = (iosProducts: string[] | undefined) => {
    if (!iosProducts) {
      return "";
    }

    return iosProducts.filter((product) => product.trim() !== "").join(",");
  };
  useEffect(() => {
    if (!props.appSettingsData) {
      return;
    }

    const selectedTenantAppSet = props.appSettingsData;

    const updatedData = {
      ...formData,
      apple: checkAppleSubscription(selectedTenantAppSet),
      sharedSecret:
        selectedTenantAppSet?.sharedSecret !== null
          ? selectedTenantAppSet?.sharedSecret
          : "",
      iosProducts: processIosProductsToString(
        selectedTenantAppSet?.iosProducts as string[]
      ),
      localyticsiOSId:
        selectedTenantAppSet?.localyticsiOSId !== null
          ? selectedTenantAppSet?.localyticsiOSId
          : "",
      localyticsAndroidId:
        selectedTenantAppSet?.localyticsAndroidId !== null
          ? selectedTenantAppSet?.localyticsAndroidId
          : "",
      myZoneEnabled: selectedTenantAppSet?.myZoneEnabled,
      subscriptionSource: selectedTenantAppSet?.subscriptionSource || [],
    };

    setFormData(updatedData);
    setApiData(updatedData);
  }, [props.appSettingsData]);;

  const submitForm = () => {
    const isError = getErrorText();
    if (isEmpty(isError)) {
      setLoading(true);
      let apiCreatePayload = getCreateApiPayload(formData);
      
      const activateApp = props?.tntByIdData?.productList
         .map((product) => product.toLowerCase())
         .includes(ACTIVATED_PRODUCT_TYPES_OBJECTS.app.toLowerCase());

      if (!activateApp) {
        setWarningModal(true);
        setLoading(false);
        return;
      }   
      dispatch(updateAppSettingsTenant(apiCreatePayload));
      setIsFormDataChanged(false);
    }
  };
  const handleWarningModalClose = () => {
    setWarningModal(false);
  };

  const showModalBox = (routingPath) => {
   setUnsavedModal(true);
   setTabRoutingPath(routingPath);
  };

  const handleUnsavedModalOkClick = () => {
    history.push(tabRoutingPath);
  };
  const handleUnsavedModalClose = () => {
    setUnsavedModal(false);
  };

  useEffect(() => {
   if (isFormDataChanged === true && apiData && formData) {
     if (isEqual(apiData, formData)) {
       setIsSameFormData(false);
     } else {
       setIsSameFormData(true);
     }
   } else {
     setIsSameFormData(false);
   }
  }, [isFormDataChanged, formData]);

  return {
    formData,
    setFormData,
    errors,
    getErrorText,
    setLoading,
    isLoading,
    submitForm,
    warningModal,
    handleWarningModalClose,
    isSameFormData,
    setIsSameFormData,
    showModalBox,
    unsavedModal,
    setIsFormDataChanged,
    handleUnsavedModalOkClick,
    handleUnsavedModalClose,
  };
};
